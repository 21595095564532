import {Typography} from "@material-ui/core";
import React from "react";
import {Link} from "react-router-dom";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    noDecoration: {
        textDecoration: 'none'
    }
}));

export default function DeceasedNameAndCompany({deceased, withLink = true}) {
    const classes = useStyles();

    const boxProps = withLink ? {
        component: Link,
        to: `/admin/deceased/${deceased.id}`
    } : {};

    return <Box {...boxProps} className={classes.noDecoration}>
        <Typography color="textPrimary">{deceased.firstName} {deceased.lastName}</Typography>
        <Typography color="textSecondary" variant="body2">{deceased.company.name}</Typography>
    </Box>;
}