import ClientApi from "../../../services/api/client/ClientApi";

class AccountingApi {
    getMonthlyReport = ({month, companyId}) => {
        return ClientApi.get('/admin/accounting/monthly_report', {
            params: {month, companyId},
        });
    }
    getFeesWithoutValues = ({month, companyId}) => {
        return ClientApi.get('/admin/accounting/fees_without_value', {
            params: {month, companyId},
        });
    }
    addFeeValue = (feeId, data) => ClientApi.patch(`/admin/accounting/fee/${feeId}/value`, data);
    getDefaultTariff = () => ClientApi.get('/admin/accounting/tariff/default');
    saveDefaultTariffItem = (feeType, data) => ClientApi.patch(`/admin/accounting/tariff/default/${feeType}`, data);
}

const accountingApi = new AccountingApi();

export default accountingApi;