import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import WaveSkeleton from "../../../Common/components/WaveSkeleton";
import StyledDialog from "../../../Common/components/Styled/StyledDialog";
import UpdateMassForm from "./UpdateMassForm";

const MassEditDialog = ({open, handleClose, mass, isFetching, deceasedId, onSuccess}) => {
    return <StyledDialog
        open={open}
        onClose={handleClose}
    >
        <DialogTitle>
            Edytuj mszę
        </DialogTitle>
        <DialogContent>
            {isFetching && <WaveSkeleton/>}
            {!isFetching && <UpdateMassForm mass={mass} deceasedId={deceasedId} onSuccess={onSuccess}/>}
        </DialogContent>
    </StyledDialog>;
}

export default MassEditDialog;