import Grid from "@material-ui/core/Grid";
import React from "react";

const SimpleColumnGrid = ({children}) => {
    return (
        <Grid container direction="column">
            {[...children].map((row, index) => <Grid item key={index}>{row}</Grid>)}
        </Grid>
    )
}

export default SimpleColumnGrid;