import deceasedApi from "../models/deceased";
import {useQuery} from "react-query";

const useFetchOrderStats = deceasedId => {
    const {isLoading, data: stats} = useQuery(
        ['orderStats', deceasedId],
        () => deceasedApi.getOrderStats({deceasedId}).then(res => res.data)
    );
    const getCountOfState = status => stats?.find(stat => stat.status === status)?.count ?? 0;
    const newCount = getCountOfState('new');
    const finishedCount = getCountOfState('finished');

    return {stats, isFetching: isLoading, newCount, finishedCount};
}

export {useFetchOrderStats};