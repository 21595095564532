import {Card, CardActionArea, CardActions, CardMedia} from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import {FaDownload, FaTrashAlt} from "react-icons/all";
import Prompt from "../../../Common/components/Prompt";
import {useBooleanState, useNoImageFallback, useWindow} from "../../../../utils/hooks";
import {useDeleteDeceasedPhoto} from "../../hooks/deceasedPhotos";

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 250,
    },
    actions: {
        padding: theme.spacing(0.5)
    }
}));

const DeceasedPhoto = ({photo, deceasedId}) => {
    const classes = useStyles();
    const {id: photoId, url} = photo;
    const {openInNewTab} = useWindow();
    const [isPromptOpen, openPrompt, closePrompt] = useBooleanState(false);
    const {isDeleting, handleDelete} = useDeleteDeceasedPhoto({deceasedId, photoId});
    const {noImageFallback} = useNoImageFallback();

    return <>
        <Prompt
            open={isPromptOpen}
            handleClose={closePrompt}
            handleAgree={handleDelete}
            dialogTitle="Usuwanie zdjęcia"
            dialogContent="Czy na pewno chcesz usunąć zdjęcie?"
            isLoading={isDeleting}
        />
        <Card className={classes.card}>
            <CardActionArea onClick={() => openInNewTab(url)}>
                <CardMedia
                    component="img"
                    alt={photoId}
                    height="140"
                    image={url}
                    title={photoId}
                    onError={noImageFallback}
                />
            </CardActionArea>
            <CardActions className={classes.actions}>
                <IconButton size="small" onClick={openPrompt}>
                    <FaTrashAlt color="secondary"/>
                </IconButton>
            </CardActions>
        </Card>
    </>;
}

export default DeceasedPhoto;
