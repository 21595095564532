import React from "react";
import Service from "./Service";

export default function Obituaries({data}) {
    return (
        <Service data={data} printRow={(row) => {
            const metadata = row.order.service.metadata;
            const type = metadata.withPhoto ? 'ze zdj.' : 'zwykłe';

            return (
                `${metadata.quantity} (${type})`
            );
        }}/>
    );
}
