import {useQuery, useQueryClient} from "react-query";
import orderApi from "../models/order";
import {useCallback} from "react";

const useFetchOrders = ({page, pageSize}) => {
    const queryClient = useQueryClient();
    const fetchOrders = useCallback(({page, pageSize}) => orderApi.fetchAll({page, limit: pageSize}).then(res => res.data), []);

    const {isLoading, data} = useQuery(
        ['orders', {page, pageSize}],
        () => fetchOrders({page, pageSize})
    );

    const prefetch = useCallback(({page, pageSize}) => {
        const obj = {page, pageSize};

        queryClient.prefetchQuery(
            ['orders', obj],
            () => fetchOrders(obj),
            {
                staleTime: 1000 * 60,
            }
        )
    }, []);

    const prefetchSidePages = () => {
        prefetch({page: Math.max(0, page - 1), pageSize});
        prefetch({page: page + 1, pageSize});
    }

    return {isLoading, data, prefetchSidePages};
}

export {useFetchOrders};