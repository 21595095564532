import Typography from "@material-ui/core/Typography";
import React from "react";
import OrderDetailsGrid from "./Helper/OrderDetailsGrid";
import PhotoComponent from "./Helper/PhotoComponent";

const PhotoDetails = ({metadata}) => {
    return <OrderDetailsGrid>
        <>
            <Typography variant="overline" color="textSecondary">
                Szczegóły
            </Typography>
            <Typography variant="body2" gutterBottom>
                {metadata.individualRequirements ? metadata.individualRequirements : metadata.size}
            </Typography>
        </>
        <PhotoComponent photoId={metadata.photoId} />
    </OrderDetailsGrid>;
}

export default PhotoDetails;