import React from "react";
import DeceasedEditDialog from "./DeceasedEditDialog";
import EditDeceasedDetails from "./EditDeceasedDetails";

const EditDeceasedDetailsDialog = ({open, deceased, onClose = () => {}, onSave = () => {}}) => {
    return (
        <DeceasedEditDialog
            deceased={deceased}
            onClose={onClose}
            onSave={onSave}
            open={open}
            EditComponent={EditDeceasedDetails}
        />
    );
}

export default EditDeceasedDetailsDialog;