import Typography from "@material-ui/core/Typography";
import React, {useState} from "react";
import {useBooleanState, useTextFormat} from "../../../utils/hooks";
import {MdEdit} from "react-icons/all";
import IconButton from "@material-ui/core/IconButton";
import AdditionalInformationForm from "./AdditionalInformationForm";

const EditableAdditionalInformation = (
    {
        orderId,
        text = null,
        defaultEditMode = false,
        closable = true
    }) => {
    const [isEditing, openEditMode, exitEditMode] = useBooleanState(defaultEditMode);
    const [additionalInformation, setContent] = useState(text);
    const {nl2br} = useTextFormat();

    return (
        <>
            {
                isEditing ?
                    <AdditionalInformationForm
                        orderId={orderId}
                        onSuccess={data => {
                            setContent(data.additionalInformation);
                            exitEditMode();
                        }}
                        defaultValue={additionalInformation}
                        exitEditMode={closable ? exitEditMode : undefined}
                    />
                    :
                    <>
                        <IconButton size="small" onClick={openEditMode}>
                            <MdEdit/>
                        </IconButton>
                        {additionalInformation ?
                            <Typography variant="body2" onClick={openEditMode} display="block">
                                {nl2br(additionalInformation)}
                            </Typography>
                            : null
                        }
                    </>
            }
        </>
    );
}

export default EditableAdditionalInformation;