import React, {Fragment, useState} from "react";
import InputErrors from "../modules/Common/components/Input/InputErrors";
import {useTranslation} from "react-i18next";
import {messageService} from "./message/MessageService";
import {dateTimeToString, dateToString} from "./time";

const useSwitchState = (defaultValue, allowUnchecked = false) => {
    const [state, setState] = useState(defaultValue);

    const handleChange = (event, newType) => {
        if (newType) {
            setState(newType);
        }

        if (allowUnchecked && null === newType) {
            setState(null);
        }
    };

    return [state, handleChange]
}

const useBooleanState = defaultValue => {
    const [state, setState] = useState(defaultValue);
    const setTrue = () => setState(true);
    const setFalse = () => setState(false);

    return [state, setTrue, setFalse]
}

const useToggleState = defaultValue => {
    const [state, setState] = useState(defaultValue);
    const toggle = () => setState(!state);

    return [state, toggle];
}

const useValidationErrors = () => {
    const [errors, setErrors] = useState({});
    const resetErrors = () => setErrors({});
    const handleError = (error) => {
        if (error?.response?.status === 422) {
            setErrors(error.response.data.errors);
        }
        if (error?.response?.status === 403) {
            alert(error.response.detail);
        }
    }
    const getErrors = fieldName => errors[fieldName];
    const isErroneous = fieldName => Boolean(errors[fieldName]);
    const getErrorsField = fieldName => <InputErrors errors={getErrors(fieldName)} fieldName={fieldName}/>
    const injectErrors = fieldName => ({
        error: isErroneous(fieldName),
        helperText: getErrorsField(fieldName)
    })

    return {resetErrors, handleError, isErroneous, getErrors, getErrorsField, injectErrors}
}

const useBooleanTranslation = () => {
    const {t} = useTranslation('common');

    const tb = (value) => value ? t('yes'): t('no');

    return {tb};
}

const useFormHelper = () => {
    const removeEmptyFields = (data) => {
        Object.keys(data).forEach(key => {
            if (data[key] === '' || data[key] == null) {
                delete data[key];
            }
        });
    }

    return {removeEmptyFields}
}

const useWindow = () => {
    const openInNewTab = url => window.open(url, '_blank');

    return {openInNewTab};
}

const useMessages = () => {
    const error = message => messageService.error(message);

    return {error};
}

const useDateFormat = () => ({dateToString, dateTimeToString})

const useTextFormat = () => {
    const nl2br = text => text
        .split('\n')
        .map((item, key) => {
            return <Fragment key={key}>{item}<br/></Fragment>
        });

    return {nl2br};
}

const useNoImageFallback = () => {
    const noImageFallback = e => {
        e.target.src = '/no_picture.png';
        e.target.style.opacity = 0.2;
    }

    return {noImageFallback};
}

export {useNoImageFallback, useTextFormat, useDateFormat, useToggleState, useMessages, useWindow, useFormHelper, useSwitchState, useBooleanState, useValidationErrors, useBooleanTranslation};