import React from "react";
import {usePageContext} from "../../../context/PageContext";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        color: '#ffffff',
    }
}));

export default function AppBreadcrumbs() {
    const classes = useStyles();
    const { pageTitle, breadcrumbs } = usePageContext();

    if (breadcrumbs.length === 0) {
        breadcrumbs.push({
            name: pageTitle
        });
    }

    return (
        <Breadcrumbs className={classes.root}>
            {breadcrumbs.map((row, index) => {
                return <Typography component="h1" variant="h6" color="inherit" key={index}>
                    {row.name}
                </Typography>
            })}
        </Breadcrumbs>
    );
}