import ClientApi from "../../../services/api/client/ClientApi";

class DeceasedApi {
    fetchAll = ({page, pageSize, companyId = null, query = null}) => {

        return ClientApi.get('/admin/deceased', {
            params: {page, limit: pageSize, companyId, query}
        });
    }
    fetchAllOrders = ({deceasedId}) => ClientApi.get(`/admin/deceased/${deceasedId}/order`);
    fetch = deceasedId => ClientApi.get(`/admin/deceased/${deceasedId}`);
    create = data => ClientApi.post('/admin/deceased', data);
    updateDeceased = (deceasedId, data) => ClientApi.put(`/admin/deceased/${deceasedId}`, data);
    updateFuneral = (deceasedId, data) => ClientApi.put(`/admin/deceased/${deceasedId}/funeral`, data);
    fetchAllPhotos = ({deceasedId}) => ClientApi.get(`/admin/deceased/${deceasedId}/photo`);
    uploadPhoto = (deceasedId, data) => ClientApi.post(`/admin/deceased/${deceasedId}/photo`, data);
    deletePhoto = (deceasedId, photoId) => ClientApi.delete(`/admin/deceased/${deceasedId}/photo/${photoId}`);
    addMass = (deceasedId, data) => ClientApi.post(`/admin/deceased/${deceasedId}/mass`, data);
    getOrderStats = ({deceasedId}) => ClientApi.get(`/admin/deceased/${deceasedId}/order_stats`);
    updateMass = (deceasedId, massId, data) => ClientApi.put(`/admin/deceased/${deceasedId}/mass/${massId}`, data);
}

const deceasedApi = new DeceasedApi();

export default deceasedApi;