import {useQuery} from "react-query";
import companyApi from "../models/company";

const useFetchCompanies = () => {
    const {data, isLoading} = useQuery(
        'companies',
        () => companyApi.fetchAll().then(res => res.data),
        {
            initialData: []
        }
    );

    return {companies: data, isFetching: isLoading}
}

export {useFetchCompanies};