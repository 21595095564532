import React from "react";
import {Chip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import WaveSkeleton from "../../Common/components/WaveSkeleton";
import {useFetchOrderStats} from "../hooks/deceasedOrders";

const shared = () => ({
    new: {
        backgroundColor: "#f1c232"
    },
    finished: {
        backgroundColor: "#6aa84f",
        color: "#ffffff"
    },
    canceled: {
        backgroundColor: "#cc0000",
        color: "#ffffff"
    }
});

const useStyles = makeStyles((theme) => ({
    ...shared(theme),
    root: {
        '& > *': {
            margin: theme.spacing(0.3)
        }
    }
}))

export default function OrderStats({deceasedId}) {
    const classes = useStyles();
    const {isFetching, newCount, finishedCount} = useFetchOrderStats(deceasedId);

    const createProps = (number, className = null) => {
        let props = {
            label: number,
            size: "small"
        }

        if (number === 0) {
            props.variant = "outlined";
        } else {
            props.variant = "default";
            props.className = className
        }

        return props;
    };

    return <>
        {isFetching ?
            <WaveSkeleton/>
            :
            <div className={classes.root}>
                <Chip {...createProps(newCount, classes.new)}/>
                <Chip {...createProps(finishedCount, classes.finished)}/>
            </div>
        }
    </>
}