import {Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useFormContext} from "react-hook-form";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {useBooleanState} from "../../../../../utils/hooks";
import MassSelectDialog from "../../Mass/MassSelectDialog";
import MassGrid from "../../Mass/MassGrid";

const useStyles = makeStyles(theme => ({
    toggleButton: {
        textTransform: "none",
        maxWidth: 250
    },
    box: {
        padding: theme.spacing(1),
        width: 300,
        minWidth: 200,
    },
    innerBox: {
        paddingLeft: theme.spacing(1)
    },
    setMassButton: {
        marginTop: theme.spacing(1)
    }
}));

const MassFormSelect = ({deceasedId, autoOpen = false}) => {
    const classes = useStyles();
    const {register} = useFormContext();
    const [mass, setMass] = useState(null);
    const [isDialogOpen, showDialog, hideDialog] = useBooleanState(false);

    useEffect(() => {
        if (autoOpen) {
            showDialog();
        }
    }, [autoOpen]);

    return (<>
        <input type="hidden" name="serviceMetadata[massId]"  ref={register} value={mass?.id || ''}/>

        <MassSelectDialog
            deceasedId={deceasedId}
            setMass={setMass}
            open={isDialogOpen}
            handleClose={hideDialog}
        />

        <Box className={classes.box} onClick={showDialog}>
            <Box className={classes.innerBox}>
                {mass ?
                    <MassGrid mass={mass}/>
                    :
                    <Typography color="textSecondary">
                        Brak wybranej mszy
                    </Typography>
                }
            </Box>
            <Button onClick={showDialog} className={classes.setMassButton}>
                Ustaw mszę
            </Button>
        </Box>
    </>);
}

export default MassFormSelect;