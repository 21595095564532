import React, {useEffect, useState} from "react";
import CreateDeceased from "./CreateDeceased";
import Table from "../../Common/components/Listing/Table";
import {DateWithFromNow} from "../../Common/components/Listing/DateWithFromNow";
import FuneralDetails from "../components/FuneralDetails";
import DeceasedNameAndCompany from "../components/DeceasedNameAndCompany";
import {useTranslation} from "react-i18next";
import BirthAndDeathDate from "../components/BirthAndDeathDate";
import {useCompanyContext} from "../../../context/CompanyContext";
import {usePage} from "../../../context/PageContext";
import {FiPlus, MdNoteAdd} from "react-icons/all";
import {useHistory} from "react-router-dom";
import {useFetchAllDeceased} from "../hooks/deceasedQuery";
import OrderStats from "../components/OrderStats";
import {useBooleanState} from "../../../utils/hooks";

export default function DeceasedList() {
    const history = useHistory();
    const {t} = useTranslation('deceased');
    const [isCreateFormOpen, openCreateForm, closeCreateForm] = useBooleanState(false);
    const {selectedCompany} = useCompanyContext();

    usePage({
        title: 'Zmarli',
    });

    const columns = [
        {
            title: t('firstNameAndSurname'),
            render: rowData => <DeceasedNameAndCompany deceased={rowData} withLink={false}/>,
        },
        {
            title: t('birthAndDeathDate'),
            render: rowData => <BirthAndDeathDate deceased={rowData}/>,
        },
        {
            title: t('funeralDate'),
            render: rowData => <DateWithFromNow date={rowData.funeral?.date}/>,
        },
        {
            title: t('funeralInfo'),
            render: rowData => <FuneralDetails funeral={rowData.funeral} deceased={rowData}/>,
        },
        {
            title: t('deceasedOrders'),
            render: rowData => <OrderStats deceasedId={rowData.id}/>,
        }
    ];

    const actions = [
        {
            icon: () => <MdNoteAdd size={26} data-test="addOrder"/>,
            tooltip: t('addOrder'),
            onClick: (event, rowData) => history.push(`deceased/${rowData.id}/order`),
        },
        {
            icon: () => <FiPlus data-test="createDeceasedButton" />,
            tooltip: t('addDeceased'),
            isFreeAction: true,
            onClick: openCreateForm
        }
    ];

    const [fetcherQuery, setQuery] = useState({page: 0, pageSize: 25, companyId: selectedCompany?.id, query: undefined});
    const {data, isLoading: fetchingAllDeceased, prefetchSidePages} = useFetchAllDeceased(fetcherQuery);

    useEffect(
        () => setQuery({...fetcherQuery, companyId: selectedCompany?.id, page: 0}),
        [selectedCompany]
    );

    useEffect(prefetchSidePages, [fetcherQuery]);

    return <div data-test="deceasedList">
        <CreateDeceased open={isCreateFormOpen} onClose={closeCreateForm}/>
        <Table
            onChangeQueryParams={(queryParams => setQuery({...fetcherQuery, ...queryParams}))}
            isRemoteSearch={true}
            isLoading={fetchingAllDeceased}
            data={data?.items ?? undefined}
            page={data?.page ?? 0}
            totalCount={data?.totalItems ?? 0}
            columns={columns}
            actions={actions}
            onRowClick={(event, rowData) => history.push(`/admin/deceased/${rowData.id}`)}
        />
    </div>;
}