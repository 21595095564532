import {useForm} from "react-hook-form";
import orderApi from "../models/order";
import {useBooleanState, useValidationErrors} from "../../../utils/hooks";

const useAddAdditionalInformation = ({orderId, onSuccess = () => {}, formValues = {}} = {}) => {
    const [loading, startLoading, stopLoading] = useBooleanState(false)
    const {register, handleSubmit, reset} = useForm(formValues);
    const {resetErrors, handleError, injectErrors} = useValidationErrors();

    const doSubmit = (data) => {
        startLoading();
        resetErrors()

        orderApi.addAdditionalInformation(orderId, data)
            .then(() => {
                reset(data);
                onSuccess(data);
            })
            .catch(error => handleError(error))
            .finally(stopLoading);
    }

    return {injectErrors, loading, form: {register, handleSubmit: handleSubmit(doSubmit)}};
}

export {useAddAdditionalInformation};