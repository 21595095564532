import {Tooltip, Typography} from "@material-ui/core";
import React from "react";
import Grid from "@material-ui/core/Grid";
import {dateTimeToString, fromNow} from "../../../utils/time";
import OrderStatus from "./OrderStatus";

export default function OrderStatusWithDate({order}) {
    return (
        <Tooltip title={fromNow(order.createdAt)}>
            <Grid container direction="column">
                <Grid item>
                    <OrderStatus orderStatus={order.status}/>
                </Grid>
                <Grid item>
                    <Typography variant="body2" color="textSecondary">{dateTimeToString(order.createdAt)}</Typography>
                </Grid>
            </Grid>
        </Tooltip>
    );
}