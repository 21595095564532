import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import {useTranslation} from "react-i18next";

const Prompt = ({open, handleAgree, handleClose, dialogTitle, dialogContent, isLoading = false}) => {
    const {t} = useTranslation('common');

    return <Dialog
        open={open}
        onClose={handleClose}
    >
        <DialogTitle>
            {dialogTitle}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {dialogContent}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleClose}
                color="secondary"
            >
                {t('action.cancel')}
            </Button>
            <Button
                onClick={handleAgree}
                color="primary"
                variant="contained"
                autoFocus
                disabled={isLoading}
            >
                {isLoading ? <CircularProgress size={24}/> : t('action.confirm')}
            </Button>
        </DialogActions>
    </Dialog>;
}

export default Prompt;