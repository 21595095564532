import {FaAddressCard, FaImages, FaPortrait, FaShieldAlt} from "react-icons/all";
import React from "react";

export default function getIcon(serviceType, size="36px") {
    switch (serviceType) {
        case 'photo':
            return <FaPortrait size={size}/>
        case 'plate':
            return <FaShieldAlt size={size}/>
        case 'obituary':
            return <FaAddressCard size={size}/>
        case 'pictures':
            return <FaImages size={size}/>
        default:
            return serviceType;
    }
};