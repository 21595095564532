import Button from "@material-ui/core/Button";
import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import StyledDialog from "../../../Common/components/Styled/StyledDialog";
import {useFetchDeceased} from "../../../Deceased/hooks/deceased";
import WaveSkeleton from "../../../Common/components/WaveSkeleton";
import {makeStyles} from "@material-ui/core/styles";
import MassGrid from "./MassGrid";
import Typography from "@material-ui/core/Typography";
import AddMassForm from "./AddMassForm";

const useStyles = makeStyles(theme => ({
    toggleButton: {
        textTransform: "none",
        width: 233,
        margin: theme.spacing(1)
    },
    addTypography: {
        marginTop: theme.spacing(2)
    },
}))

const MassSelectDialog = ({deceasedId, setMass, open, handleClose}) => {
    const classes = useStyles();
    const handleClick = mass => {
        setMass(mass);
        handleClose();
    };
    const {deceased, isFetching, fetch} = useFetchDeceased(deceasedId);
    const onMassAdd = mass => {
        handleClose();
        setMass(mass);
        fetch();
    };

    const masses = deceased?.masses;
    const hasNoMasses = masses?.length === 0;

    return <StyledDialog
        open={open}
        onClose={handleClose}
        titleText="Wybierz lub utwórz mszę"
    >
        <DialogContent>
            {isFetching && <WaveSkeleton/>}
            {!isFetching && hasNoMasses && <Typography color="textSecondary">Brak mszy. Dodaj poniżej.</Typography>}
            {masses?.map(mass =>
                <Button
                    key={mass.id}
                    onClick={() => handleClick(mass)}
                    className={classes.toggleButton}
                >
                    <MassGrid mass={mass}/>
                </Button>
            )}
            <Typography variant="h6" className={classes.addTypography}>
                Dodaj
            </Typography>
            <AddMassForm deceasedId={deceasedId} onSuccess={onMassAdd}/>
        </DialogContent>
    </StyledDialog>
}

export default MassSelectDialog;