import userDataStorage from "../../../services/user/UserDataStorage";
import {useHistory} from "react-router-dom";
import loginApi from "../../../services/api/LoginApi";
import {useBooleanState} from "../../../utils/hooks";

const defaults = {
    onError: message => {}
}

const useUserAuth = () => {
    const history = useHistory();
    const [isLogging, startLogging, stopLogging] = useBooleanState(false);

    const doLogout = () => {
        userDataStorage.clear();
        history.push('/login')
    };

    const doLogin = (username, password, options = {onError: () => {}}) => {
        startLogging();

        const opts = {...defaults, ...options};

        loginApi.login(username, password)
            .then(response => {
                userDataStorage.persist(response.data)
                history.push('/admin');
            })
            .catch(error => {
                userDataStorage.clear();
                opts.onError(error.response.data.message);
            })
            .finally(stopLogging);
    }

    const isLoggedIn = userDataStorage.hasToken();

    return {doLogin, doLogout, isLoggedIn, isLogging}
}

export {useUserAuth}