import {makeStyles} from "@material-ui/core/styles";
import React, {useState} from "react";
import Card from "@material-ui/core/Card";
import {CardContent, TableContainer} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import FeeValueAction from "./FeeValueAction";
import {messageService} from "../../../utils/message/MessageService";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
    },
    paperEmpty: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        display: 'flex',
    }
}));

export default function FeesWithoutValue({fees, afterClearingFees}) {
    const classes = useStyles();
    const [data, setData] = useState(fees);
    const {t} = useTranslation('accounting');

    const removeFee = (feeToRemove) => {
        const newFees = data.filter(fee => fee.id !== feeToRemove.id);

        setData(newFees);

        if (newFees.length === 0) {
            messageService.info(t('allFeesWithValueAction'));
            afterClearingFees();
        }
    }

    return (
        <>
            {data.length !== 0 &&
            <Card className={classes.paper}>
                <CardContent>
                    <Typography variant="h5" component="div">Zlecenia do rozliczenia</Typography>
                    <TableContainer className={classes.paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Lp.</TableCell>
                                    <TableCell>Zamówienie</TableCell>
                                    <TableCell>Szczegóły zamówienia</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((fee, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}.</TableCell>
                                            <TableCell>{fee.order.individualId}</TableCell>
                                            <TableCell>...</TableCell>
                                            <TableCell>
                                                <FeeValueAction fee={fee} onSuccess={removeFee}/>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
            }
        </>
    );
}