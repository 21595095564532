import React, {createContext, useContext} from "react";
import {useFetchCompanies} from "../modules/User/hooks/company";

const CompaniesFetcherContext = createContext([]);

const useCompaniesFetcherContext = () => useContext(CompaniesFetcherContext);

const CompaniesFetcherContextProvider = ({children}) => {
    const {companies} = useFetchCompanies();

    return <CompaniesFetcherContext.Provider value={companies}>
        {children}
    </CompaniesFetcherContext.Provider>;
}

export { CompaniesFetcherContext, useCompaniesFetcherContext, CompaniesFetcherContextProvider };