import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import {DatePicker} from "@material-ui/pickers";
import {Controller, useForm} from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {useCompaniesFetcherContext} from "../../../context/CompaniesFetcherContext";
import {useAcl} from "../../ACL/hooks/acl";
import AclHidden from "../../ACL/components/AclHidden";
import SpinningSubmitButton from "../../Common/components/Form/SpinningSubmitButton";
import {useFetchFeesWithoutValue, useFetchMonthlyReport} from "../hook/report";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    }
}));

export default function ReportForm({setReportData, setFeesWithoutValues, setRefreshReportAction}) {
    const classes = useStyles();
    const {t} = useTranslation('accounting');
    const [date, setDate] = useState(new Date());
    const {register, handleSubmit, control} = useForm({defaultValues: {
            companyId: "",
        }});
    const [formData, setFormData] = useState({});
    const canSeeFeesWithoutValues = useAcl("SeeFeesWithoutValues");
    const companies = useCompaniesFetcherContext();

    const {isLoading: isLoadingReport, refetch: refetchReport} = useFetchMonthlyReport(formData);
    const {isLoading: isLoadingFees, refetch: refetchFees, injectErrors} = useFetchFeesWithoutValue(formData);

    const doSubmit = data => {
        setFormData(data);
        setReportData(null);
        setFeesWithoutValues(null);

        if(canSeeFeesWithoutValues) {
            Promise.all([
                refetchReport(),
                refetchFees()
            ])
                .then(res => {
                    setReportData(res[0].data);
                    setFeesWithoutValues(res[1].data);
                })
        } else {
            refetchReport().then(res => setReportData(res.data))
        }
    }

    useEffect(() => {
        setRefreshReportAction(() => handleSubmit(doSubmit));
    }, []);

    return (
        <Paper className={classes.paper}>
            <form noValidate className={classes.root} onSubmit={handleSubmit(doSubmit)}>
                <FormControl>
                    <FormGroup>
                        <DatePicker
                            fullWidth
                            margin="normal"
                            autoOk
                            disableFuture
                            clearable
                            format="yyyy-MM"
                            openTo="year"
                            inputVariant="outlined"
                            name="month"
                            views={["year", "month"]}
                            label="Raport za miesiąc"
                            value={date}
                            onChange={setDate}
                            inputRef={register}
                            {...injectErrors('month')}
                        />
                    </FormGroup>
                    <AclHidden permission="SeeReportsForOtherComapanies">
                        <FormGroup>
                            <Controller
                                name="companyId"
                                control={control}
                                as={
                                    <TextField
                                        id="companyId"
                                        select
                                        label="Firma"
                                        variant="outlined"
                                        {...injectErrors('companyId')}
                                    >
                                        {companies.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                }/>

                        </FormGroup>
                    </AclHidden>
                    <FormGroup>
                        <SpinningSubmitButton
                            loading={isLoadingReport || isLoadingFees}
                            ButtonProps={{
                                className: classes.button,
                                fullWidth: true
                            }}
                        >
                            {t('action.show', {ns: "common"})}
                        </SpinningSubmitButton>
                    </FormGroup>
                </FormControl>
            </form>
        </Paper>
    );
}