import React from "react";
import Service from "./Service";

export default function Plates({data}) {
    return (
        <Service data={data} printRow={(row) => {
            const metadata = row.order.service.metadata;

            return (
                `1x ${metadata.size}`
            );
        }}/>
    );
}
