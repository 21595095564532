const Config = {
    apiUrl: window.ENV?.REACT_APP_API_URL || "http://localhost:8888/api",
    backendVersion: window.ENV?.REACT_APP_BACKEND_VERSION || "dev",
    frontendVersion: window.ENV?.REACT_APP_FRONTEND_VERSION || "dev"
}

const useConfig = () => {
    return Config;
}

export default Config;

export {useConfig};