import React from "react";
import Service from "./Service";

export default function Pictures({data}) {
    return (
        <Service data={data} printRow={(row) => {
            const metadata = row.order.service.metadata;

            return (
                `${metadata.quantity} szt.`
            );
        }}/>
    );
}
