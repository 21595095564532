import deceased_pl from "../modules/Deceased/translations/pl.json";
import common_pl from "../modules/Common/translations/pl.json";
import order_pl from "../modules/Order/translations/pl.json";
import accounting_pl from "../modules/Accounting/translations/pl.json";
import admin_pl from "../modules/Admin/translations/pl.json";
import i18next from "i18next";
import {initReactI18next} from "react-i18next";

const resources = {
    pl: {
        deceased: deceased_pl,
        common: common_pl,
        order: order_pl,
        accounting: accounting_pl,
        admin: admin_pl
    }
};

i18next
    .use(initReactI18next)
    .init({
        resources,
        interpolation: {
            escapeValue: false
        },
        lng: 'pl',
        keySeparator: '.'
    });

export default i18next;