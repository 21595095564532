import userDataStorage from "../../user/UserDataStorage";

const authHeaderValue = () => {
    const token = userDataStorage.getToken();

    return token ? 'Bearer ' + token : null;
}

function authHeader() {
    const headerValue = authHeaderValue();

    if (headerValue) {
        return {
            Authorization: headerValue
        }
    }

    return {};
}

const useAuth = () => {
    return {
        header: authHeader,
        headerValue: authHeaderValue
    };
}

export default authHeader;

export {authHeaderValue, useAuth};