import React, {useEffect, useState} from "react";
import DialogContent from "@material-ui/core/DialogContent";
import StyledDialog from "../../../Common/components/Styled/StyledDialog";

const DeceasedEditDialog = ({open, deceased, EditComponent, onClose = () => {}, onSave = () => {}}) => {
    const [isOpen, setIsOpen] = useState(open);

    const doOnClose = () => {
        onClose();
        setIsOpen(false);
    }

    const doOnSave = () => {
        onSave();
        setIsOpen(false);
    }

    useEffect(() => {
        setIsOpen(open);
    }, [open])

    if (!deceased) return '';

    return <>
        {deceased &&
        <StyledDialog
            open={isOpen}
            onClose={doOnClose}
            fullWidth={true}
            titleText={<>{deceased.firstName} {deceased.lastName}</>}
        >
            <DialogContent>
                <EditComponent deceased={deceased} onSuccess={doOnSave}/>
            </DialogContent>
        </StyledDialog>
        }
    </>;
}
export default DeceasedEditDialog;