import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {dateToString} from "../../../../utils/time";
import {MyKeyboardDatePicker} from "../../../Common/components/Pickers/Pickers";

const DeceasedDetailsForm = ({register, injectErrors, deceased = null}) => {
    const [selectedBirthDate, handleBirthDateChange] = useState(null);
    const [selectedDeathDate, handleDeathDateChange] = useState(null);
    const {t} = useTranslation('deceased');

    useEffect(() => {
        if (deceased?.birthDate) {
            const birthDate = dateToString(deceased.birthDate, 'YYYY-MM-DD');
            handleBirthDateChange(birthDate);
        }
        if (deceased?.deathDate) {
            const deathDate = dateToString(deceased?.deathDate, 'YYYY-MM-DD');
            handleDeathDateChange(deathDate);
        }

    }, [deceased]);

    return <>
        <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="firstName"
            label={t('firstName')}
            name="firstName"
            autoFocus
            inputRef={register}
            {...injectErrors('firstName')}
        />
        <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="lastName"
            label={t('lastName')}
            id="lastName"
            inputRef={register}
            {...injectErrors('lastName')}
        />
        <MyKeyboardDatePicker
            fullWidth
            margin="normal"
            autoOk
            disableFuture
            clearable
            label={t("birthDate")}
            value={selectedBirthDate}
            onChange={handleBirthDateChange}
            openTo="year"
            views={["year", "month", "date"]}
            inputVariant="outlined"
            name="birthDate"
            inputRef={register}
            {...injectErrors('birthDate')}
        />
        <MyKeyboardDatePicker
            fullWidth
            margin="normal"
            autoOk
            disableFuture
            clearable
            label={t('deathDate')}
            value={selectedDeathDate}
            onChange={handleDeathDateChange}
            openTo="year"
            views={["year", "month", "date"]}
            inputVariant="outlined"
            name="deathDate"
            inputRef={register}
            {...injectErrors('deathDate')}
        />
    </>
}

export default DeceasedDetailsForm;
