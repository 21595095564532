import {useQuery, useQueryClient} from "react-query";
import {useCallback} from "react";
import deceasedApi from "../models/deceased";
import {useFormHelper} from "../../../utils/hooks";

const useFetchAllDeceased = ({page, pageSize, query, companyId}) => {
    const {removeEmptyFields} = useFormHelper();
    const params = {page, pageSize, query, companyId};

    removeEmptyFields(params);

    const queryClient = useQueryClient();
    const fetchAllDeceased = useCallback((params) =>
        deceasedApi.fetchAll(params)
            .then(res => res.data), []);

    const {isLoading, data} = useQuery(
        ['deceaseds', params],
        () => fetchAllDeceased(params)
    );

    const prefetch = useCallback(({page, pageSize, companyId, query}) => {
        const obj = {page, pageSize, companyId, query};

        queryClient.prefetchQuery(
            ['deceaseds', obj],
            () => fetchAllDeceased(obj),
            {
                staleTime: 1000 * 60,
            }
        )
    }, []);

    const prefetchSidePages = () => {
        if (!query) {
            prefetch({...params, page: Math.max(0, page - 1)});
            prefetch({...params, page: page + 1});
        }
    }

    return {isLoading, data, prefetchSidePages};
}

export {useFetchAllDeceased};