import MessageBox from "../../../components/common/message/MessageBox";
import {Redirect, Route, Switch} from "react-router-dom";
import PrivateRoute from "../../Common/components/Routing/PrivateRoute";
import CreateOrder from "../../Order/pages/CreateOrder";
import DeceasedPage from "../../Deceased/pages/DeceasedPage";
import DeceasedList from "../../Deceased/pages/DeceasedList";
import OrderList from "../../Order/pages/OrderList";
import MonthlyReport from "../../Accounting/pages/MonthlyReport";
import AccountingSettings from "../../Settings/pages/AccountingSettings";
import Box from "@material-ui/core/Box";
import React from "react";
import Copyright from "./Copyright";

const MainContent = () => {
    return <>
        <MessageBox/>

        <Switch>
            <PrivateRoute path='/admin/deceased/:deceasedId/order' component={CreateOrder}/>
            <PrivateRoute path='/admin/deceased/:deceasedId' component={DeceasedPage}/>
            <PrivateRoute exact path='/admin/deceased' component={DeceasedList}/>
            <PrivateRoute exact path='/admin/order' component={OrderList}/>
            <PrivateRoute exact path='/admin/monthly_report' component={MonthlyReport}/>
            <PrivateRoute exact path='/admin/settings/accounting' component={AccountingSettings}/>
            <Route exact path="/admin">
                <Redirect to="/admin/deceased" />
            </Route>
            <Redirect to="/admin"/>
        </Switch>

        <Box pt={4}>
            <Copyright/>
        </Box>
    </>
}

export default MainContent;