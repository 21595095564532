import React, {createContext, useContext, useEffect, useMemo, useState} from "react";

const PageContext = createContext({
    breadcrumbs: [],
    pageTitle: '',
    actions: {
        updateBreadcrumbs: () => {},
        updatePageTitle: () => {}
    }
});

const usePageContext = () => useContext(PageContext);

const usePage = ({title = null, breadcrumbs = []}) => {
    const {actions} = usePageContext();

    useEffect(() => {
        actions.updatePageTitle(title);
        actions.updateBreadcrumbs(breadcrumbs);
    }, [actions])
}

const PageContextProvider = ({children}) => {
    const [breadcrumbs, updateBreadcrumbs] = useState([]);
    const [pageTitle, updatePageTitle] = useState(null);

    useEffect(() => {
        if (pageTitle) {
            document.title = pageTitle + " | Styx App"
        } else {
            document.title = "Styx App";
        }
    }, [pageTitle])

    const actions = useMemo(
        () => ({
            updateBreadcrumbs: (breadcrumbs) => updateBreadcrumbs(breadcrumbs),
            updatePageTitle: (title) => updatePageTitle(title),
        }),
    []
    );

    return (
        <PageContext.Provider value={{ breadcrumbs, pageTitle, actions }}>
            {children}
        </PageContext.Provider>
    );
}

export { PageContext, usePageContext, usePage, PageContextProvider}