import {Redirect, Route} from "react-router-dom";
import React from "react";
import {useUserAuth} from "../../../User/hooks/auth";

const PrivateRoute = ({component: Component, ...rest}) => {
    const {isLoggedIn} = useUserAuth();

    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
                )
            }
        />
    )
}
export default PrivateRoute;