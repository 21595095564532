import React from "react";
import {useFetchDeceased} from "../hooks/deceased";
import {Typography} from "@material-ui/core";
import {dateToString} from "../../../utils/time";
import WaveSkeleton from "../../Common/components/WaveSkeleton";
import {DateWithFromNow} from "../../Common/components/Listing/DateWithFromNow";

const DeceasedSimpleData = ({deceasedId}) => {
    const {deceased, isFetching} = useFetchDeceased(deceasedId);

    if (isFetching) return <WaveSkeleton/>;
    if (!deceased) return <Typography variant="h6">Zmarły nie istnieje</Typography>;

    return <div>
        <Typography variant="h5">
            {deceased.firstName} {deceased.lastName}
        </Typography>
        <Typography variant="body2" color="textSecondary">
            {deceased.company.name}
        </Typography>
        <Typography variant="body1">
            Data śmierci: {deceased.deathDate ? dateToString(deceased.deathDate) : "---"}
        </Typography>
        <Typography variant="body1">
            Data pogrzebu: <DateWithFromNow date={deceased.funeral?.date} oneLine/>
        </Typography>
    </div>;
}

export default DeceasedSimpleData;