import React, {createContext, useContext, useState} from "react";

const CompanyContext = createContext({
    selectedCompany: null,
    setSelectedCompany: () => {}
});

const useCompanyContext = () => useContext(CompanyContext);

const CompanyContextProvider = ({children}) => {
    const [selectedCompany, setSelectedCompany] = useState(null);

    return (
        <CompanyContext.Provider value={{selectedCompany, setSelectedCompany}}>
            {children}
        </CompanyContext.Provider>
    );
}

export { CompanyContextProvider, CompanyContext, useCompanyContext };