import {Chip} from "@material-ui/core";
import React from "react";
import {BsInfoCircle, ImCancelCircle, IoMdDoneAll} from "react-icons/all";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    new: {
        backgroundColor: "#f1c232"
    },
    finished: {
        backgroundColor: "#6aa84f",
        color: "#ffffff"
    },
    canceled: {
        backgroundColor: "#cc0000",
        color: "#ffffff"
    }
}));

const getIcon = (status) => {
    switch (status) {
        case 'new':
            return <BsInfoCircle/>
        case 'finished':
            return <IoMdDoneAll color="white"/>;
        case 'canceled':
            return <ImCancelCircle color="white"/>
        default:
            return '';
    }
}

export default function OrderStatus({orderStatus}) {
    const classes = useStyles();
    const {t} = useTranslation('order');

    return (
        <Chip
            icon={getIcon(orderStatus)}
            size="small"
            label={t(`statuses.${orderStatus}`)}
            className={classes[orderStatus]}
        />
    )
}