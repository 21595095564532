import Typography from "@material-ui/core/Typography";
import React from "react";
import {useCompanyContext} from "../../../context/CompanyContext";

export default function SelectedCompany() {
    const companyContext = useCompanyContext();

    return (
        companyContext.selectedCompany &&
        <Typography component="h2" variant="h5">
            {companyContext.selectedCompany.name}
        </Typography>

    );
}