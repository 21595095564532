import Grid from "@material-ui/core/Grid";
import React from "react";

const OrderDetailsGrid = ({children}) => {
    const iterableChildren = React.Children.toArray(children);

    return <Grid container>
        {[...iterableChildren].map((row, index) => <Grid item xs={12} sm={12} md={6} lg={6} key={index}>{row}</Grid>)}
    </Grid>
}

export default OrderDetailsGrid;