import {useTranslation} from "react-i18next";

const useServiceType = () => {
    const {t} = useTranslation('order');

    const serviceTypes = [{
        label: t('serviceTypes.obituary'),
        type: "obituary"
    }, {
        label: t('serviceTypes.plate'),
        type: "plate"
    }, {
        label: t('serviceTypes.photo'),
        type: "photo"
    }, {
        label: t('serviceTypes.pictures'),
        type: "pictures"
    }];

    const getServiceLabel = type => serviceTypes
        .find(serviceType => serviceType.type === type)
        .label;

    return {serviceTypes, getServiceLabel};
}

export default useServiceType;