import TextField from "@material-ui/core/TextField";
import ControlledAutocomplete from "../../../Common/components/Input/ControlledAutocomplete";
import React from "react";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useCreateChurch} from "../../hooks/church";

export default function ChurchSelect({churches, defaultChurchId = null, control = null}) {
    const formContext = useFormContext();
    const {create, isLoading} = useCreateChurch();
    const {t} = useTranslation('deceased');

    if (null === control && null !== formContext) {
        const {control: contextControl} = formContext;
        control = contextControl;
    }

    return (
        <ControlledAutocomplete
            disabled={isLoading}
            name="churchId"
            options={churches}
            control={control}
            defaultValue={defaultChurchId}
            onCreate={async value => {
                const response = await create(value);
                return {id: response?.data?.id, name: value}
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t('church')}
                    variant="outlined"
                    margin="normal"
                />
            )}
        />
    )
}