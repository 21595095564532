import {Skeleton} from "@material-ui/lab";
import React from "react";

const WaveSkeleton = () => {
    const widths = ['85%', '100%', '70%'];

    return (
        <>
            {widths.map((width, index) =>
                <Skeleton key={index} animation="wave" width={width}/>)}
        </>
    );
}

export default WaveSkeleton;