import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import React, {useEffect, useState} from "react";
import Collapse from "@material-ui/core/Collapse";

export default function Message(props) {
    const [open, setOpen] = useState(true);

    const {content, severity, autoHide = true} = props.message;
    const defaultSettings = {
        hideIn: 7500,
    };
    const settings = {...defaultSettings, ...props, autoHide};
    const {hideIn} = settings;

    useEffect(() => {
        let timer = null;
        if (settings.autoHide && hideIn > 0) {
            timer = setTimeout(() => setOpen(false), hideIn);
        }

        return () => clearTimeout(timer);
    }, [settings.autoHide, hideIn])

    return (
        <Collapse in={open}>
            <Alert
                data-test-severity={severity}
                severity={severity}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon fontSize="inherit"/>
                    </IconButton>
                }
            >
                {content}
            </Alert>
        </Collapse>
    );
}