import React, {useEffect, useState} from 'react';
import {useFormContext} from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import {Checkbox} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DeceasedPhotoSelect from './Input/DeceasedPhotoSelect'

export default function ObituaryForm({injectErrors, deceasedId}) {
    const {register, reset} = useFormContext();

    const [withPhoto, setWithPhoto] = useState(false);
    const handleChangeWithPhoto = event => setWithPhoto(event.target.checked);

    useEffect(() => {
        reset({
            serviceMetadata: {
                quantity: 5
            }
        })
    }, []);

    return <>
        <FormGroup>
            <TextField
                autoFocus
                {...injectErrors('serviceMetadata.quantity')}
                type="number"
                variant="outlined"
                margin="dense"
                id="quantity"
                name="serviceMetadata[quantity]"
                label="Ile sztuk?"
                placeholder="0"
                InputProps={{
                    endAdornment: <InputAdornment position="start">szt.</InputAdornment>,
                    inputProps: {
                        min: 1,
                        step: 1
                    }
                }}
                inputRef={register}
            />
        </FormGroup>
        <FormGroup>
            <TextField
                {...injectErrors('serviceMetadata.additionalInformation')}
                variant="outlined"
                margin="dense"
                id="additionalInformation"
                multiline
                name="serviceMetadata[additionalInformation]"
                label="Dodatkowe informacje"
                inputRef={register}
            />
        </FormGroup>

        <FormGroup>
            <FormControlLabel
                control={<Checkbox/>}
                label="Ze zdjęciem?"
                name="serviceMetadata[withPhoto]"
                checked={withPhoto}
                onChange={handleChangeWithPhoto}
                inputRef={register}
            />
        </FormGroup>
        {withPhoto && <DeceasedPhotoSelect deceasedId={deceasedId}/>}
    </>;
}