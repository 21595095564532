import React from "react";
import DeceasedDetailsForm from "../Form/DeceasedDetailsForm";
import {useUpdateDeceasedDetails} from "../../hooks/deceased";
import FormControl from "@material-ui/core/FormControl";
import {useTranslation} from "react-i18next";
import SpinningSubmitButton from "../../../Common/components/Form/SpinningSubmitButton";

const EditDeceasedDetails = ({deceased, onSuccess = () => {}}) => {
    const {loading, injectErrors, form: {register, handleSubmit}} = useUpdateDeceasedDetails({deceased, onSuccess});
    const {t} = useTranslation('common');

    return (
        <form noValidate onSubmit={handleSubmit}>
            <FormControl fullWidth>
                <DeceasedDetailsForm register={register} injectErrors={injectErrors} deceased={deceased}/>
                <FormControl margin="normal">
                    <SpinningSubmitButton loading={loading}>
                        {t('action.save')}
                    </SpinningSubmitButton>
                </FormControl>
            </FormControl>
        </form>
    );
}

export default EditDeceasedDetails;