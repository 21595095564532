import {Button} from "@material-ui/core";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useUploadDeceasedPhoto} from "../../hooks/deceasedPhotos";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    button: {
        width: 100,
        marginTop: 15
    },
    noDisplay: {
        display: 'none'
    }
}));

const DeceasedPhotoUploadButton = ({deceasedId}) => {
    const classes = useStyles();
    const {t} = useTranslation('common');
    const {upload, isUploading} = useUploadDeceasedPhoto({deceasedId})
    const handleChange = (e) => upload(e.target.files);

    return (<>
        <input
            accept="image/*"
            className={classes.noDisplay}
            id="upload-deceased-photo"
            type="file"
            onChange={handleChange}
        />
        <label htmlFor="upload-deceased-photo">
            <Button
                disabled={isUploading}
                variant="outlined"
                component="span"
                color="primary"
                className={classes.button}
            >
                {isUploading ? <CircularProgress size={24}/> : t('action.upload')}
            </Button>
        </label>
    </>)
}

export default DeceasedPhotoUploadButton;