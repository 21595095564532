import axios from 'axios';
import {messageService} from "../../../utils/message/MessageService";
import i18n from "../../../utils/i18n"
import Config from "../../../config/config";
import {authHeaderValue} from "../helpers/authHeader";

const headerValue = authHeaderValue();

const axiosInstance = axios.create({
  baseURL: Config.apiUrl,
  timeout: 60000,
});

const onFulfilled = config => {
  return config;
};

const onRejected = error => {
  if (error.message === 'Network Error') {
    messageService.error(i18n.t("backendNetworkError", {ns: 'common'}));

    console.error(error);
  }

  if (error?.response?.status === 401 && error?.response?.data?.message === 'Expired JWT Token') {
    messageService.warning(i18n.t("tokenExpired", {ns: 'common'}))
  }

  if (error?.response?.status === 403) {
    messageService.error(i18n.t("accessDenied", {ns: 'common'}))
  }

  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(onFulfilled, onRejected)
axiosInstance.interceptors.request.use(config => {
  config.headers.Authorization = headerValue;

  return config;
});

export default axiosInstance;