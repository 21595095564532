import {Card} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {useGetGroupedDefaultTariffItems} from "../../Accounting/hook/tariff";
import WaveSkeleton from "../../Common/components/WaveSkeleton";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {useTranslation} from "react-i18next";
import Container from "@material-ui/core/Container";
import TariffItemRow from "../components/Tariff/TariffItemRow";
import {usePage} from "../../../context/PageContext";

const AccountingSettings = () => {
    const {isFetching, getGroups, getItems, refresh} = useGetGroupedDefaultTariffItems();
    const {t: to} = useTranslation('order');
    const {t} = useTranslation('accounting');

    usePage({
        title: 'Ustawienia rozliczeń',
        breadcrumbs: [
            {name: 'Ustawienia'},
            {name: 'Rozliczenia'},
        ]});

    if (isFetching) return <WaveSkeleton/>;

    const groups = getGroups();

    return <Container maxWidth="lg">
        <Grid container spacing={2}>{groups.map(group => (
            <Grid key={group} item xs={12} md={6}>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5">
                            {to(`serviceTypes.${group}`)}
                        </Typography>
                        <Table size="small">
                            <TableBody>
                                {getItems(group).map(item => <TariffItemRow item={item} key={item.type} afterUpdate={refresh}/>)}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>

        ))}
        </Grid>
    </Container>;
}

export default AccountingSettings;