import accountingApi from "../models/accounting";
import {useQuery} from "react-query";
import {useValidationErrors} from "../../../utils/hooks";

const useFetchMonthlyReport = ({month, companyId}) => {
    const {isLoading, refetch} = useQuery(
        ['monthlyReport', {month, companyId}],
        ({queryKey}) => {
            const [, {month, companyId}] = queryKey;

            return accountingApi.getMonthlyReport({month, companyId}).then(res => res.data)
        } ,{
            enabled: false,
            retry: false,
        }
    );

    return {isLoading, refetch};
}

const useFetchFeesWithoutValue = ({month, companyId}) => {
    const {injectErrors, handleError} = useValidationErrors()
    const {isLoading, refetch} = useQuery(
        ['feeWithoutValue', {month, companyId}],
        ({queryKey}) => {
            const [, {month, companyId}] = queryKey;

            return accountingApi.getFeesWithoutValues({month, companyId}).then(res => res.data)
        } ,{
            enabled: false,
            retry: false,
            onError: err => handleError(err)
        }
    );

    return {isLoading, refetch, injectErrors};
}

export {useFetchMonthlyReport, useFetchFeesWithoutValue};