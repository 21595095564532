import React from "react";
import {Chip} from "@material-ui/core";

const InlineChip = ({label}) => {
    return <Chip
        label={label}
        size="small"
        style={{marginLeft: '5px'}}
    />;
}

export default InlineChip;
