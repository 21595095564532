import {useTranslation} from "react-i18next";
import {FaPortrait, GiSpikedHalo} from "react-icons/all";
import React from "react";

const usePicturesHook = () => {
    const {t} = useTranslation('order');

    const types = [
        {name: 'saint', icon: <GiSpikedHalo size={50}/>, label: t('pictures.type.saint')},
        {name: 'photo', icon: <FaPortrait size={50}/>, label: t('pictures.type.photo')}
    ];

    return {types}
}

export {usePicturesHook};