import React, {useState} from "react";
import ReportForm from "../components/ReportForm";
import ReportTable from "../components/ReportTable";
import FeesWithoutValue from "../components/FeesWithoutValue";
import {usePage} from "../../../context/PageContext";

export default function MonthlyReport() {
    const [reportData, setReportData] = useState(null);
    const [feesWithoutValues, setFeesWithoutValues] = useState(null);
    const [refreshReportAction, setRefreshReportAction] = useState(() => {});

    usePage({
        title: "Raport",
        breadcrumbs: [
            {name: "Raport"}
        ]
    });

    return <>
        <ReportForm
            setReportData={setReportData}
            setFeesWithoutValues={setFeesWithoutValues}
            setRefreshReportAction={setRefreshReportAction}
        />
        {feesWithoutValues &&
            <FeesWithoutValue
                fees={feesWithoutValues}
                afterClearingFees={refreshReportAction}
            />
        }
        {reportData &&
            <ReportTable data={reportData}/>
        }
    </>;
}