import React from "react";
import {useBooleanTranslation} from "../../../../utils/hooks";
import Typography from "@material-ui/core/Typography";
import OrderDetailsGrid from "./Helper/OrderDetailsGrid";
import PhotoComponent from "./Helper/PhotoComponent";

const ObituaryDetails = ({metadata}) => {
    const {tb} = useBooleanTranslation();

    return <OrderDetailsGrid>
        <>
            <Typography variant="overline" color="textSecondary">
                Szczegóły
            </Typography>
            <Typography variant="body2" gutterBottom>
                {metadata.quantity} szt. <br/>
                Ze zdjęciem: {tb(metadata.withPhoto)}<br/>
                {metadata.additionalInformation}
            </Typography>
        </>
        <PhotoComponent photoId={metadata.photoId} />
    </OrderDetailsGrid>;
}

export default ObituaryDetails;