import Dialog from "@material-ui/core/Dialog";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        background: 'rgba(0, 0, 0, 0.5)',
    },
}));

export default function StyledDialog(props) {
    const {titleText = null, children, onClose, ...rest} = props;
    const classes = useStyles();
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return <Dialog
            PaperProps={{
                'data-test': 'dialogModal'
            }}
            fullScreen={mobile}
            fullWidth
            maxWidth={"sm"}
            BackdropProps={{
                classes: {
                    root: classes.backdrop
                }
            }}
            onClose={onClose}
            {...rest}
        >
            {titleText &&
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1} >
                        {titleText}
                    </Box>
                    <Box>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            }
            {children}
        </Dialog>;
}