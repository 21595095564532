import jwtDecode from "jwt-decode";

const key = 'token';

class UserDataStorage {
    persist = userData => window.localStorage.setItem(key, JSON.stringify(userData))
    clear = () => window.localStorage.removeItem(key)
    getUserDetails = () => {
        const userData = window.localStorage.getItem(key);

        return userData ? jwtDecode(this.getToken()).user : null;
    }
    getToken = () => {
        const userData = window.localStorage.getItem(key);

        return userData ? JSON.parse(userData).token : null;
    }
    hasToken = () => null !== this.getToken()
}

const userDataStorage = new UserDataStorage();

export default userDataStorage;