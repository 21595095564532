import React from "react";
import ObituaryDetails from "./Details/ObituaryDetails";
import PhotoDetails from "./Details/PhotoDetails";
import PicturesDetails from "./Details/PicturesDetails";
import PlateDetails from "./Details/PlateDetails";

const OrderServiceDetails = ({order}) => {
    const {service: {type, metadata}, deceased: {id : deceasedId}} = order;

    switch (type) {
        case 'photo':
            return <PhotoDetails metadata={metadata}/>;
        case 'obituary':
            return <ObituaryDetails metadata={metadata}/>;
        case 'pictures':
            return <PicturesDetails metadata={metadata} deceasedId={deceasedId}/>
        case 'plate':
            return <PlateDetails metadata={metadata}/>;
        default:
            return `${type} not handled`
    }
}

export default OrderServiceDetails;