import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Message from "./Message";
import {messageService} from "../../../utils/message/MessageService";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(1),
        },
    },
}));

export default function MessageBox() {
    const classes = useStyles();
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const subscription = messageService.getMessage().subscribe((message) => {
            setMessages([...messages, message])
        })

        return () => subscription.unsubscribe();
    });

    const messagesComponents = messages.map((message, index) => {
        return <Message key={index} message={message} />
    });

    return (
        <div className={classes.root} data-test="messageBox">
                {messagesComponents}
        </div>
    );
}
