import React, {useState} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useFormContext} from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import TwoRowColumn from "../../../Common/components/TwoRowColumn";
import {FaPortrait} from "react-icons/all";
import {useSwitchState} from "../../../../utils/hooks";
import {usePhotoHook} from "../../hooks/photo";
import DeceasedPhotoSelect from "./Input/DeceasedPhotoSelect";
import {useMediaQuery} from "@material-ui/core";

export default function PhotoForm({injectErrors, deceasedId}) {
    const {register} = useFormContext();
    const [specialReq, setSpecialReq] = useState(false);
    const {sizes} = usePhotoHook();
    const [size, handleChangeSize] = useSwitchState('A3');
    const handleChangeRequirements = event => setSpecialReq(event.target.checked);
    const mobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const style = mobile ? {minWidth: 75, maxWidth: 125} : {width: 150};

    return <>
        <FormGroup>
            {
                !specialReq
                &&
                <>
                    <FormGroup style={{marginBottom: 10}}>
                        <input type="hidden" name="serviceMetadata[size]" value={size || ''} ref={register}/>
                        <ToggleButtonGroup size="large" exclusive value={size} onChange={handleChangeSize}>
                            {sizes.map(({name, iconSize}) =>
                                <ToggleButton
                                    key={name}
                                    value={name}
                                    style={style}
                                    size={mobile ? 'small' : 'large'}
                                >
                                    <TwoRowColumn
                                        up={<FaPortrait size={iconSize}/>}
                                        bottom={name}
                                    />
                                </ToggleButton>
                            )}
                        </ToggleButtonGroup>
                    </FormGroup>
                </>
            }
        </FormGroup>
        <FormGroup>
            <FormControlLabel
                control={<Checkbox
                    checked={specialReq}
                    onChange={handleChangeRequirements}
                />}
                label="Niestandardowe wymagania"
            />
        </FormGroup>
        <FormGroup>
            {
                specialReq &&
                <TextField
                    autoFocus
                    {...injectErrors('serviceMetadata.individualRequirements')}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="individualRequirements"
                    label="Wymagania"
                    name="serviceMetadata[individualRequirements]"
                    multiline={true}
                    inputRef={register}
                    disabled={!specialReq}
                />
            }
        </FormGroup>
        <DeceasedPhotoSelect deceasedId={deceasedId}/>
    </>;
}