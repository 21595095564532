import React from "react";
import WaveSkeleton from "../../../Common/components/WaveSkeleton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {useDateFormat} from "../../../../utils/hooks";
import {useTranslation} from "react-i18next";
import InlineChip from "../../../Common/components/InlineChip";

const MassDetails = ({mass, isFetching}) => {
    const {t} = useTranslation('deceased');
    const {dateTimeToString} = useDateFormat();

    if (isFetching) return <WaveSkeleton/>;
    if (null === mass) return <Typography>Msza nieodnaleziona</Typography>

    return <Grid container direction="column">
        <Grid item>
            {dateTimeToString(mass.date) ?? '--'}
            <InlineChip label={t(`massTypes.${mass.type}`)}/>
        </Grid>
        <Grid item>
            {mass.church?.name ?? '--'}
        </Grid>
    </Grid>;
}

export default MassDetails;
