import {useEffect, useState} from "react";
import accountingApi from "../models/accounting";
import {useQuery} from "react-query";

const useFetchDefaultTariff = () => {
    const {refetch, isLoading, data} = useQuery(
        'accountingSettings',
        () => accountingApi.getDefaultTariff().then(response => response.data)
    );

    return {defaultTariff: data, isFetching: isLoading, refresh: refetch}
}

const useGetGroupedDefaultTariffItems = () => {
    const {isFetching, defaultTariff, refresh} = useFetchDefaultTariff();
    const [grouped, setGrouped] = useState([]);

    useEffect(() => {
        if (defaultTariff) {
            const groups = defaultTariff.items.reduce((groups, item) => ({
                ...groups,
                [item.group]: [...(groups[item.group] || []), item]
            }), {});

            setGrouped(groups);
        }
    }, [defaultTariff])

    const getGroups = () => Object.entries(grouped).map(([key]) => key);
    const getItems = group => defaultTariff.items.filter(item => item.group === group);

    return {isFetching, tariffItems: grouped, getGroups, getItems, refresh}
}

export {useFetchDefaultTariff, useGetGroupedDefaultTariffItems}