import {useFetchDeceasedOrders} from "../../hooks/deceased";
import React from "react";
import {TableContainer, Typography} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import ServiceType from "../../../Order/components/ServiceType";
import OrderStatus from "../../../Order/components/OrderStatus";
import {DateWithFromNow} from "../../../Common/components/Listing/DateWithFromNow";
import WaveSkeleton from "../../../Common/components/WaveSkeleton";
import OrderDetailsGrid from "../../../Order/components/Details/Helper/OrderDetailsGrid";
import OrderServiceDetails from "../../../Order/components/OrderServiceDetails";
import EditableAdditionalInformation from "../../../Order/components/EditableAdditionalInformation";

export default function DeceasedOrders({deceasedId}) {
    const {orders, isFetching} = useFetchDeceasedOrders(deceasedId);

    if (isFetching) return <WaveSkeleton/>;

    return (
        <>
            {orders.length > 0 ?
                <>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Usługa</TableCell>
                                    <TableCell>Nr zamówienia</TableCell>
                                    <TableCell>Data zlecenia</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Szczegóły</TableCell>
                                    <TableCell>Dodatkowe informacje</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.map(order => {
                                    return <TableRow key={order.id}>
                                        <TableCell>
                                            <ServiceType serviceType={order.service.type}/>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{order.individualId}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <DateWithFromNow date={order.createdAt}/>
                                        </TableCell>
                                        <TableCell>
                                            <OrderStatus orderStatus={order.status} />
                                        </TableCell>
                                        <TableCell>
                                            <OrderServiceDetails order={order} />
                                        </TableCell>
                                        <TableCell>
                                            <EditableAdditionalInformation
                                                orderId={order.id}
                                                text={order.additionalInformation}
                                            />
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
                : "Brak zamówień"
            }
        </>
    );
}
