import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {useDateFormat} from "../../../../utils/hooks";
import {useTranslation} from "react-i18next";

const MassGrid = ({mass}) => {
    const {dateTimeToString} = useDateFormat();
    const {t} = useTranslation('deceased');


    return <Grid container direction="column">
        <Grid item>
            <Typography variant="h5" color="textPrimary">
                {t(`massTypes.${mass.type}`)}
            </Typography>
        </Grid>
        <Grid item>
            <Typography color="textSecondary">{dateTimeToString(mass.date) ?? '--'}</Typography>
        </Grid>
        <Grid item>
            <Typography>{mass.church?.name ?? '--'}</Typography>
        </Grid>
    </Grid>;
}

export default MassGrid;