import {Subject} from "rxjs";

const subject = new Subject();
const messageType = {
    error: 'error',
    success: 'success',
    info: 'info',
    warning: 'warning',
}
export const messageService = {
    error: (message) => subject.next({content: message, severity: messageType.error}),
    success: (message) => subject.next({content: message, severity: messageType.success}),
    info: (message) => subject.next({content: message, severity: messageType.info}),
    warning: (message) => subject.next({content: message, severity: messageType.warning}),
    clearMessages: () => subject.next(),
    getMessage: () => subject.asObservable()
};