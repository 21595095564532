const usePhotoHook = () => {
    const sizes = [
        {name: 'A5', iconSize: 36},
        {name: 'A4', iconSize: 48},
        {name: 'A3', iconSize: 64},
    ];

    return {sizes}
}

export {usePhotoHook};