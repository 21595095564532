import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import FormGroup from "@material-ui/core/FormGroup";
import React from "react";
import {useNoImageFallback, useSwitchState} from "../../../../../utils/hooks";
import {useFetchDeceasedPhotos} from "../../../../Deceased/hooks/deceasedPhotos";
import {useFormContext} from "react-hook-form";
import {Card, CardMedia, useMediaQuery} from "@material-ui/core";
import WaveSkeleton from "../../../../Common/components/WaveSkeleton";
import DeceasedPhotoUploadButton from "../../../../Deceased/components/Photo/DeceasedPhotoUploadButton";
import Typography from "@material-ui/core/Typography";

const DeceasedPhotoSelect = ({deceasedId}) => {
    const {register} = useFormContext();
    const {photos, isFetching} = useFetchDeceasedPhotos(deceasedId);
    const [photoId, handleChangePhoto] = useSwitchState(null, true);
    const mobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const {noImageFallback} = useNoImageFallback();
    const orientation = mobile
        ? photos.length >= 4
            ? "vertical"
            : "horizontal"
        : "horizontal";
    const style = mobile
        ? photos.length >= 4
            ? {width: 250}
            : {width: 100}
        : {width: 150};

    if (isFetching) return <WaveSkeleton/>;

    return <>
        <FormGroup>
            <input type="hidden" name="serviceMetadata[photoId]" value={photoId || ''} ref={register}/>
            <ToggleButtonGroup
                orientation={orientation}
                size={mobile ? "small" : "large"}
                exclusive
                value={photoId}
                onChange={handleChangePhoto}

            >
                {photos.length === 0 && <Typography color="textSecondary">Brak dodanych zdjęć</Typography>}
                {photos.map(({id, url}) =>
                    <ToggleButton
                        key={id}
                        value={id}
                        style={style}
                    >
                        <Card>
                            <CardMedia
                                onError={noImageFallback}
                                component="img"
                                alt={id}
                                height="140"
                                image={url}
                                title={id}
                            />
                        </Card>
                    </ToggleButton>
                )}
            </ToggleButtonGroup>
        </FormGroup>
        <DeceasedPhotoUploadButton deceasedId={deceasedId} />
    </>;
}

export default DeceasedPhotoSelect;