import MaterialTable from "material-table";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

const defaultPageSize = 25;

export default function Table(props) {
    const {onChangeQueryParams, columns, isRemoteSearch = false, data, ...rest} = props;
    const [queryParams, setQueryParams] = useState({
        page: 0,
        pageSize: defaultPageSize,
        query: null,
    });

    const customFilterAndSearch = useCallback(() => true, []);

    const cols = useMemo(() =>
        isRemoteSearch
            ? columns.map(col => ({...col, customFilterAndSearch}))
            : columns, [columns, isRemoteSearch]);

    const updateParams = params => setQueryParams((prevState => ({...prevState, ...params})));

    const {t} = useTranslation('common');

    useEffect(() => {
        if (onChangeQueryParams) {
            onChangeQueryParams(queryParams);
        }
    }, [queryParams]);

    return <MaterialTable
            {...rest}
            data={data}
            columns={cols}
            onChangePage={(page, pageSize) => updateParams({page, pageSize})}
            onSearchChange={searchText => updateParams({query: searchText})}
            onChangeRowsPerPage={pageSize => updateParams({pageSize})}
            options={{
                actionsColumnIndex: -1,
                paginationType: "stepped",
                searchFieldAlignment: "left",
                pageSizeOptions: [10, 25, 50, 100],
                pageSize: defaultPageSize,
                debounceInterval: 500,
                padding: "dense",
                showEmptyDataSourceMessage: false,
                showTitle: false,
                sorting: false,
                filtering: false,
                columnResizable: false,
                draggable: false,
                emptyRowsWhenPaging: false,
            }}
            localization={{
                header: {
                    actions: t("actions")
                },
                toolbar: {
                    searchTooltip: t("search"),
                    searchPlaceholder: t("search")
                },
                pagination: {
                    nextTooltip: t("nextPage"),
                    nextAriaLabel: t("nextPage"),
                    lastAriaLabel: t("lastPage"),
                    lastTooltip: t("lastPage"),
                    previousAriaLabel: t("previousPage"),
                    previousTooltip: t("previousPage"),
                    firstAriaLabel: t("firstPage"),
                    firstTooltip: t("firstPage"),
                    labelRowsSelect: t("rows")
                }
            }
            }
        />;
}