import SpinningButtonLabel from "../SpinningButtonLabel";
import Button from "@material-ui/core/Button";
import React from "react";

const SpinningSubmitButton = ({loading, children, ButtonProps = {}}) => {
    return <Button
        color="primary"
        variant="contained"
        disabled={loading}
        type="submit"
        {...ButtonProps}
    >
        <SpinningButtonLabel
            loading={loading}
            label={children}
        />
    </Button>;
}

export default SpinningSubmitButton;