import React, {useEffect, useState} from 'react';
import {useFormContext} from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import {Checkbox, useMediaQuery} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import TwoRowColumn from "../../../Common/components/TwoRowColumn";
import {BsCircleFill} from "react-icons/all";
import {useSwitchState} from "../../../../utils/hooks";
import {usePlateHook} from "../../hooks/plate";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Img from "../../../Common/components/Img";
import DeceasedPhotoSelect from "./Input/DeceasedPhotoSelect";

const useStyles = makeStyles(theme => ({
    formGroup: {
        marginBottom: theme.spacing(2)
    },
}))

export default function PlateForm({injectErrors, deceasedId}) {
    const classes = useStyles();
    const {register} = useFormContext();
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [isStandard, setIsStandard] = useState(true);
    const handleChangeIsStandard = event => setIsStandard(!event.target.checked);

    const [placement, handleChangePlacement] = useSwitchState('cross');
    const [shape, handleChangeShape] = useSwitchState(null);
    const [color, handleChangeColor] = useSwitchState(null);
    const [size, handleChangeSize] = useSwitchState(null);

    const [withPhoto, setWithPhoto] = useState(false);
    const handleChangeWithPhoto = event => setWithPhoto(event.target.checked);

    const style = mobile ? {minWidth: 75, maxWidth: 125} : {width: 150};
    const imgWidth = mobile ? 75 : 125;

    const {placements, colors, getShapesForPlacement, getSizesForPlacement, defaultValues} = usePlateHook();

    useEffect(() => {
        const defaults = defaultValues[placement];

        handleChangeShape(null, defaults.shape);
        handleChangeSize(null, defaults.size);
        handleChangeColor(null, defaults.color);
    }, [placement]);

    return <>
        <FormGroup>
            <FormControlLabel
                control={<Checkbox
                    checked={!isStandard}
                    onChange={handleChangeIsStandard}
                />}
                label="Niestandardowe"
            />
        </FormGroup>
        {isStandard
            ?
            <>
                <FormGroup className={classes.formGroup}>
                    <input type="hidden" name="serviceMetadata[placement]" value={placement} ref={register}/>
                    <ToggleButtonGroup size="large" exclusive value={placement} onChange={handleChangePlacement}>
                        {placements.map(({name, icon, label}) =>
                            <ToggleButton key={name} value={name} style={style} size={mobile ? 'small': 'large'}>
                                <TwoRowColumn
                                    up={icon}
                                    bottom={label}
                                />
                            </ToggleButton>
                        )}
                    </ToggleButtonGroup>
                </FormGroup>

                <FormGroup className={classes.formGroup}>
                    <input type="hidden" name="serviceMetadata[shape]" value={shape} ref={register}/>
                    <ToggleButtonGroup size="large" exclusive value={shape} onChange={handleChangeShape}>
                        {getShapesForPlacement(placement).map(({name, label, image}) =>
                            <ToggleButton key={name} value={name} style={style} size={mobile ? 'small': 'large'}>
                                <TwoRowColumn
                                    up={<Img src={image} width={imgWidth}/>}
                                    bottom={label}
                                />
                            </ToggleButton>)}
                    </ToggleButtonGroup>
                </FormGroup>

                <FormGroup className={classes.formGroup}>
                    <input type="hidden" name="serviceMetadata[color]" value={color} ref={register}/>
                    <ToggleButtonGroup size="large" exclusive value={color} onChange={handleChangeColor}>
                        {colors.map(({name, color, label}) =>
                            <ToggleButton key={name} value={name} style={style} size={mobile ? 'small': 'large'}>
                                <TwoRowColumn
                                    up={<BsCircleFill size="50px" color={color}/>}
                                    bottom={label}
                                />
                            </ToggleButton>)}
                    </ToggleButtonGroup>
                </FormGroup>

                <FormGroup className={classes.formGroup}>
                    <input type="hidden" name="serviceMetadata[size]" value={size} ref={register}/>
                    <ToggleButtonGroup size="large" exclusive value={size} onChange={handleChangeSize}>
                        {getSizesForPlacement(placement).map(({name, icon, label}) =>
                            <ToggleButton key={name} value={name} style={style} size={mobile ? 'small': 'large'}>
                                <TwoRowColumn
                                    up={icon}
                                    bottom={label}
                                />
                            </ToggleButton>)}
                    </ToggleButtonGroup>
                </FormGroup>
            </>
            :
            <TextField
                {...injectErrors('serviceMetadata.custom')}
                variant="outlined"
                margin="dense"
                id="custom"
                name="serviceMetadata[custom]"
                label="Niestandardowa tabliczka"
                placeholder="Proszę wpisać wszystkie wymagane informacje"
                autoFocus
                inputRef={register}
            />
        }

        <FormGroup>
            <FormControlLabel
                control={<Checkbox />}
                label="Ze zdjęciem?"
                checked={withPhoto}
                onChange={handleChangeWithPhoto}
            />
        </FormGroup>
        {withPhoto && <DeceasedPhotoSelect deceasedId={deceasedId}/>}

    </>;
}