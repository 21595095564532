import {Card, CardMedia} from "@material-ui/core";
import React from "react";
import {useFetchPhoto} from "../../../Photo/hooks/photo";
import WaveSkeleton from "../../../Common/components/WaveSkeleton";
import {useTranslation} from "react-i18next";
import {useNoImageFallback} from "../../../../utils/hooks";

const PhotoPreview = ({photoId}) => {
    const {photo, isFetching} = useFetchPhoto(photoId);
    const {t} = useTranslation('deceased');
    const {noImageFallback} = useNoImageFallback();

    if (isFetching) return <WaveSkeleton/>

    return <>
        {photo ?
            <Card style={{maxWidth: '100px'}}>
                <CardMedia
                    component="img"
                    alt={photo.id}
                    height="100"
                    image={photo.url}
                    title={photo.id}
                    onError={noImageFallback}
                />
            </Card> : t('photoNotFound')
        }
    </>;
}

export default PhotoPreview;
