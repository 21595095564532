import React from "react";
import {RecoilRoot} from 'recoil';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Login from './components/login/Login';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import PublicRoute from "./modules/Common/components/Routing/PublicRoute";
import PrivateRoute from "./modules/Common/components/Routing/PrivateRoute";
import AdminWrapper from "./components/AdminWrapper";
import 'moment/locale/pl'; // todo
import {ErrorBoundary} from "react-error-boundary";
import {QueryClient, QueryClientProvider} from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'

export default function App() {
    const locale = "pl"; // todo move to context
    const queryClient = new QueryClient();

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            <QueryClientProvider client={queryClient}>
                <RecoilRoot>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale={locale} libInstance={moment}>
                        <BrowserRouter>
                            <Switch>
                                <PublicRoute restricted={true} path="/login" exact component={Login}/>}
                                <PrivateRoute path='/admin' component={AdminWrapper}/>
                                <Route render={() => <Redirect to="/login"/>}/>
                            </Switch>
                        </BrowserRouter>
                    </MuiPickersUtilsProvider>
                </RecoilRoot>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </ErrorBoundary>
    );
}

function ErrorFallback({error, resetErrorBoundary}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
}
