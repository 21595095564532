import churchApi from "../models/church";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useMessages} from "../../../utils/hooks";
import {useTranslation} from "react-i18next";

const useFetchChurches = () => {
    const {data, isLoading} = useQuery(
        'churches',
        () => churchApi.fetchAll().then(res => res.data)
    );

    return {churches: data, isFetching: isLoading}
}

const useCreateChurch = () => {
    const {error: errorFunc} = useMessages();
    const {t} = useTranslation('common');
    const queryClient = useQueryClient();

    const mutation = useMutation(
        name => churchApi.create({name}),
        {
            onSuccess: () => queryClient.invalidateQueries('churches'),
            onError: () => errorFunc(t('errorOccurred'))
        }
    );

    return {
        create: mutation.mutate,
        isLoading: mutation.isLoading
    };
}

export { useFetchChurches, useCreateChurch };