import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import accountingApi from "../../../Accounting/models/accounting";
import {useBooleanState} from "../../../../utils/hooks";

const TariffItemRowEdit = ({item, onSave, onClose}) => {
    const {t} = useTranslation('accounting');
    const {money, type} = item;
    const value = money?.amount ?? 0;
    const [isFetching, startFetching, stopFetching] = useBooleanState(false);
    const {register, handleSubmit} = useForm({
        defaultValues: {
            value: value
        }
    });

    const doSubmit = data => {
        startFetching();

        if (value != data.value) {
            accountingApi.saveDefaultTariffItem(type, data)
                .then(() => {
                    stopFetching();
                    onSave();
                });
        } else {
            onClose();
            stopFetching();
        }
    }

    return <TableCell colSpan="2">
        <form noValidate onSubmit={handleSubmit(doSubmit)}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={10}>
                    <TextField
                        disabled={isFetching}
                        inputRef={register}
                        type="number"
                        autoFocus
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        id="individualRequirements"
                        label={t(`feeTypes.${type}`)}
                        name="value"
                        InputProps={{
                            endAdornment: <InputAdornment position="start">zł</InputAdornment>,
                            inputProps: {
                                min: 0,
                                step: 1
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        disabled={isFetching}
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Zapisz
                    </Button>
                </Grid>
            </Grid>
        </form>
    </TableCell>
}

export default TariffItemRowEdit;