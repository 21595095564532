import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import MainListItems from "./MainListItems";
import UserListItems from "./UserListItems";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import UserDetails from "./UserDetails";

const useStyles = makeStyles(theme => ({
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    }
}));

const MenuDrawer = ({onClickItem}) => {
    const classes = useStyles();

    return <div>
        <div className={classes.toolbarIcon}>
            <IconButton onClick={onClickItem}>
                <ChevronLeftIcon/>
            </IconButton>
        </div>
        <Divider/>
        <UserDetails/>
        <Divider/>
        <List>
            <MainListItems onClickItem={onClickItem}/>
        </List>
        <Divider/>
        <List onClick={onClickItem}>
            <UserListItems />
        </List>
    </div>;
}

export default MenuDrawer;