import {Link, useParams} from "react-router-dom";
import {useFetchDeceased} from "../hooks/deceased";
import {Typography} from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import {FaEdit, MdNoteAdd} from "react-icons/all";
import IconButton from "@material-ui/core/IconButton";
import {useTranslation} from "react-i18next";
import {usePage} from "../../../context/PageContext";
import clsx from "clsx";
import DeceasedDetails from "../components/DeceasedPage/DeceasedDetails";
import DeceasedFuneralDetails from "../components/DeceasedPage/DeceasedFuneralDetails";
import DeceasedOrders from "../components/DeceasedPage/DeceasedOrders";
import DeceasedPhotos from '../components/DeceasedPage/DeceasedPhotos';
import WaveSkeleton from "../../Common/components/WaveSkeleton";
import EditFuneralDialog from "../components/Edit/EditFuneralDialog";
import EditDeceasedDetailsDialog from "../components/Edit/EditDeceasedDetailsDialog";
import {useBooleanState} from "../../../utils/hooks";

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2)
    },
    cardContent: {
        padding: 0
    }
}))

export default function DeceasedPage() {
    const [isEditFuneralOpen, openFuneralDialog, closeFuneralDialog] = useBooleanState(false);
    const [isEditDetailsOpen, openDetailsDialog, closeDetailsDialog] = useBooleanState(false);
    const classes = useStyles();
    const {deceasedId} = useParams();
    const {deceased, isFetching} = useFetchDeceased(deceasedId);
    const {t} = useTranslation('deceased');
    const skeleton = <WaveSkeleton/>;

    usePage({
        title: "Zmarły",
        breadcrumbs: [
            {name: "Zmarli"},
            {name: "Szczegóły"}
        ]
    })

    if (!isFetching && !deceased) return <Typography variant="h6">Zmarły nie istnieje</Typography>;

    return (
        <>
            <EditFuneralDialog
                deceased={deceased}
                open={isEditFuneralOpen}
                onClose={closeFuneralDialog}
                onSave={closeFuneralDialog}
            />
            <EditDeceasedDetailsDialog
                deceased={deceased}
                open={isEditDetailsOpen}
                onClose={closeDetailsDialog}
                onSave={closeDetailsDialog}
            />
            <Grid container>
                <Grid item xs={12} sm={12} md={6}  lg={4}>
                    <Card className={classes.card}>
                        <CardHeader
                            title={t('details')}
                            action={
                                <IconButton onClick={openDetailsDialog}>
                                    <FaEdit/>
                                </IconButton>
                            }
                        />
                        <CardContent className={clsx(!isFetching && classes.cardContent)}>
                            {isFetching ? skeleton : <>
                                <DeceasedDetails deceased={deceased}/>
                            </>}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Card className={classes.card}>
                        <CardHeader
                            title={t('funeralInfo')}
                            action={
                                <IconButton onClick={openFuneralDialog}>
                                    <FaEdit/>
                                </IconButton>
                            }
                        />
                        <CardContent className={clsx((!isFetching) && classes.cardContent)}>
                            {isFetching ? skeleton : <>
                                <DeceasedFuneralDetails deceased={deceased} />
                            </>}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Card className={classes.card}>
                        <CardHeader
                            title={t('photos')}
                        />
                        <CardContent>
                            <DeceasedPhotos deceasedId={deceasedId} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Card className={classes.card}>
                        <CardHeader
                            title={t('deceasedOrders')}
                            action={
                                <IconButton component={Link} to={`/admin/deceased/${deceasedId}/order`}>
                                    <MdNoteAdd/>
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <DeceasedOrders deceasedId={deceasedId}/>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}