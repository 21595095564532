import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FormProvider} from "react-hook-form";
import {useFetchCemeteries} from "../../hooks/cemetery";
import {useFetchChurches} from "../../hooks/church";
import {dateTimeToString} from "../../../../utils/time";
import WaveSkeleton from "../../../Common/components/WaveSkeleton";
import {Typography} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import CemeterySelect from "../Input/CemeterySelect";
import ChurchSelect from "../Input/ChurchSelect";
import SpinningSubmitButton from "../../../Common/components/Form/SpinningSubmitButton";
import {useUpdateDeceasedFuneral} from "../../hooks/deceased";
import {MyKeyboardDateTimePicker} from "../../../Common/components/Pickers/Pickers";

export default function EditFuneralDetails({deceased, onSuccess = () => {}}) {
    const [selectedFuneralDate, handleFuneralDateChange] = useState(null);
    const {t} = useTranslation('deceased');

    const {loading, form: formMethods, injectErrors} = useUpdateDeceasedFuneral({deceased, onSuccess});
    const {register, reset, handleSubmit} = formMethods;
    const {cemeteries, isFetching: isFetchingCemeteries} = useFetchCemeteries();
    const {churches, isFetching: isFetchingChurches} = useFetchChurches();

    const isFetching = isFetchingCemeteries || isFetchingChurches;

    useEffect(() => {
        if (deceased) {
            const {funeral} = deceased;

            if (funeral) {
                const funeralDate = dateTimeToString(funeral.date, 'YYYY-MM-DD hh:mm');
                funeral.date = funeralDate;

                reset(funeral);
                handleFuneralDateChange(funeralDate);
            }
        }
    }, [deceased])

    if (isFetching) return <WaveSkeleton/>;
    if (!isFetching && !deceased) return <Typography variant="h6">Zmarły nie istnieje</Typography>;

    const defaultChurchId = deceased.funeral?.church?.id ?? null;

    return <FormProvider {...formMethods}>
        <form noValidate onSubmit={handleSubmit}>
            <FormControl fullWidth>
                <MyKeyboardDateTimePicker
                    fullWidth
                    margin="normal"
                    autoOk
                    clearable
                    label={t('funeralDate')}
                    minutesStep={5}
                    value={selectedFuneralDate}
                    onChange={handleFuneralDateChange}
                    inputVariant="outlined"
                    name="date"
                    inputRef={register}
                    {...injectErrors('date')}
                />
                <ChurchSelect defaultChurchId={defaultChurchId} churches={churches}/>
                <CemeterySelect deceased={deceased} cemeteries={cemeteries}/>
                <FormControl margin="normal">
                    <SpinningSubmitButton loading={loading}>
                        {t('action.save', {ns: 'common'})}
                    </SpinningSubmitButton>
                </FormControl>
            </FormControl>
        </form>
    </FormProvider>;
}
