import {BiRectangle, BiSticker, FaCross, FaMonument, GiCoffin, GiEgyptianUrns} from "react-icons/all";
import React from "react";
import {useTranslation} from "react-i18next";

const usePlateHook = () => {
    const {t} = useTranslation('order');

    const placements = [{
        name: 'cross',
        icon: <FaCross size="50px"/>,
        label: t('plate.placement.cross')
    }, {
        name: 'casket',
        icon: <GiEgyptianUrns size="50px"/>,
        label: t('plate.placement.casket')
    }, {
        name: 'coffin',
        icon: <GiCoffin size="50px"/>,
        label: t('plate.placement.coffin')
    }, {
        name: 'memorial',
        icon: <FaMonument size="50px"/>,
        label: t('plate.placement.memorial')
    }];

    const getPlacement = name => placements.find(placement => placement.name === name);

    const colors = [{
        name: 'gold',
        color: '#D9C27E',
        label: t('plate.color.gold')
    }, {
        name: 'silver',
        color: '#E2E8E8',
        label: t('plate.color.silver')
    }, {
        name: 'white',
        color: '#f7f8f5',
        label: t('plate.color.white')
    }, {
        name: 'silver_transparent',
        color: '#708090',
        label: t('plate.color.silver_transparent')
    }];

    const getColor = name => colors.find(color => color.name === name);

    const shapes = [{
        placements: ['cross'],
        shapes: [
            {name: 'ET08', label: 'Tarcza prezydencka', image: 'http://egrami.pl/wp-content/uploads/2015/04/11_ET08_800pic1.png'},
            {name: 'ET02', label: 'Owal', image: 'http://egrami.pl/wp-content/uploads/2015/04/05_ET02_800pic_pion1.png'},
            {name: 'ET07', label: 'Trapez', image: 'http://egrami.pl/wp-content/uploads/2015/04/10_ET07_800pic1.png'},
            {name: 'ET06', label: 'Laurka', image: 'http://egrami.pl/wp-content/uploads/2015/04/09_ET06_800pic1.png'}
        ]
    }, {
        placements: ['coffin', 'memorial'],
        shapes: [
            {name: 'ET03', label: 'Prostokąt PL', image: 'http://egrami.pl/wp-content/uploads/2015/04/06_ET03_800pic1.png'},
            {name: 'ET04', label: 'Prostokąt WA', image: 'http://egrami.pl/wp-content/uploads/2015/04/07_ET04_800pic1.png'},
            {name: 'EM01', label: 'Medium natrumienna', image: 'http://egrami.pl/wp-content/uploads/2015/04/24_EM01_800pic1.png'},
            {name: 'EM02', label: 'Medium rogi ścięte', image: 'http://egrami.pl/wp-content/uploads/2015/04/25_EM02_800pic1.png'},
            {name: 'SMALL_FOR_COFFIN', label: 'SMALL_FOR_COFFIN'}
        ]
    }, {
        placements: ['casket'],
        shapes: [
            {name: 'EU10', label: 'Urnowa prostokąt klasyczny', image: 'http://egrami.pl/wp-content/uploads/2015/04/22_EU10_800pic1.png'}
        ]
    }];

    const getShape = name => {
        const newShapes = [];
        shapes.map(row => newShapes.push(...row.shapes))

        return newShapes.find(shape => shape.name === name);
    }

    const getShapesForPlacement = placement => shapes.find(shape => shape.placements.includes(placement)).shapes;

    const sizes = [
        {
            name: 'large',
            placements: ['cross', 'coffin', 'memorial'],
            icon: <BiRectangle size={50}/>,
            label: 'Duża'
        },
        {
            name: 'small',
            placements: ['coffin', 'casket', 'memorial'],
            icon: <BiRectangle size={30}/>,
            label: 'Mała'
        },
        {
            name: 'sticker',
            placements: ['coffin'],
            icon: <BiSticker size={40}/>,
            label: 'Naklejka'
        }
    ];

    const getSize = size => sizes.filter(row => row.name === size).shift();

    const getSizesForPlacement = placement => {
        return sizes
            .filter(size => size.placements.includes(placement));
    }

    const defaultValues = {
        cross: {shape: 'ET08', color: 'gold', size: 'large'},
        casket: {shape: 'EU10', color: 'gold', size: 'small'},
        coffin: {shape: 'EM01', color: 'gold', size: 'large'},
        memorial: {shape: 'EM02', color: 'gold', size: 'large'},
    }

    return {
        placements,
        colors,
        getShapesForPlacement,
        getSizesForPlacement,
        defaultValues,
        getPlacement,
        getColor,
        getShape,
        getSize
    };
}

export {usePlateHook};