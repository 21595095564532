import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import {FaSave} from "react-icons/all";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useForm} from "react-hook-form";
import CircularProgress from "@material-ui/core/CircularProgress";
import accountingApi from "../models/accounting";
import InputErrors from "../../Common/components/Input/InputErrors";

const useStyles = makeStyles(theme => ({
    dFlex: {
        display: 'flex'
    }
}));

export default function FeeValueAction({fee, onSuccess}) {
    const classes = useStyles();
    const {register, handleSubmit} = useForm({
        defaultValues: {
            value: 0.0
        }
    });
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const doSubmit = (data) => {
        setIsLoading(true)
        setErrors({});

        const newData = {
            value: parseFloat(data.value)
        };

        accountingApi.addFeeValue(fee.id, newData)
            .then(response => {
                setIsLoading(false);
                onSuccess(fee);
            })
            .catch(error => {
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }
                setIsLoading(false)
            })
    }

    return (
        <Grid container spacing={2}>
            <Grid item className={classes.dFlex}>
                <form noValidate onSubmit={handleSubmit(doSubmit)}>
                    <TextField
                        error={Boolean(errors.value)}
                        helperText={<InputErrors errors={errors.value}/>}
                        disabled={isLoading}
                        type="number"
                        variant="outlined"
                        margin="dense"
                        id="value"
                        name="value"
                        label="Wartość"
                        placeholder="0.00"
                        InputProps={{
                            endAdornment: <InputAdornment position="start">zł</InputAdornment>,
                            inputProps: {
                                min: 0,
                                step: 0.01
                            }
                        }}
                        inputRef={register}
                    />

                    <IconButton
                        disabled={isLoading}
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        {isLoading && <CircularProgress size={24}/>}
                        {!isLoading && <FaSave/>}
                    </IconButton>
                </form>
            </Grid>
        </Grid>
    );
}