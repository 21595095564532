import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import {usePage} from "../../../context/PageContext";
import CreateOrderForm from "../components/CreateOrderForm";
import DeceasedSimpleData from "../../Deceased/components/DeceasedSimpleData";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
}));

export default function CreateOrder({match, history}) {
    const classes = useStyles();
    const {deceasedId} = match.params;

    usePage({
        title: 'Zlecenia',
        breadcrumbs: [
            {name: 'Zlecenia'},
            {name: 'Dodaj'},
        ]});

    return <div className={classes.root}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <DeceasedSimpleData deceasedId={deceasedId}/>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <CreateOrderForm deceasedId={deceasedId} history={history}/>
            </Grid>
        </Grid>
    </div>;
}