import Card from "@material-ui/core/Card";
import React from "react";
import {CardContent} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import EditableAdditionalInformation from "./EditableAdditionalInformation";
import OrderServiceDetails from "./OrderServiceDetails";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 500,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
}));

export default function OrderDetailPanel({order}) {
    const classes = useStyles();
    const {t} = useTranslation('order');

    return (
        <Grid
            container
            alignItems="center"
            justify="center"
        >
            <Grid item xs={12} md={6} lg={4}>
                <Card className={classes.root}>
                    <CardContent>
                        <OrderServiceDetails order={order}/>
                        <Typography variant="overline" color="textSecondary">
                            {t('additionalInformation')}
                        </Typography>
                        <EditableAdditionalInformation
                            orderId={order.id}
                            text={order.additionalInformation}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

    );
}