import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {useForm} from "react-hook-form";
import MessageBox from "../common/message/MessageBox";
import Copyright from "../../modules/Admin/components/Copyright";
import {useUserAuth} from "../../modules/User/hooks/auth";
import {useMessages} from "../../utils/hooks";
import SpinningButtonLabel from "../../modules/Common/components/SpinningButtonLabel";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function Login() {
  const classes = useStyles();
  const {error: errorFunc} = useMessages();
  const { register, handleSubmit } = useForm();
  const {doLogin: doLoginBase, isLogging: loading} = useUserAuth();

  const doLogin = ({username, password}) => {
    doLoginBase(username, password, {
      onError: error => errorFunc(error)
    });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Styx Panel
        </Typography>
        <MessageBox/>
        <form className={classes.form} noValidate onSubmit={handleSubmit(doLogin)}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Adres e-mail"
            name="username"
            autoComplete="email"
            autoFocus
            inputRef={register}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Hasło"
            type="password"
            id="password"
            autoComplete="current-password"
            inputRef={register}
          />
          <div className={classes.wrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.submit}
            >
              <SpinningButtonLabel loading={loading} label="Zaloguj"/>
            </Button>
          </div>
        </form>
      </div>
      <Copyright />
    </Container>
  );
}
