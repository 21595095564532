import React from "react";
import SimpleColumnGrid from "./SimpleColumnGrid";

const TwoRowColumn = ({up, bottom}) => {
    return <SimpleColumnGrid>
        {up}
        {bottom}
    </SimpleColumnGrid>;
}

export default TwoRowColumn;