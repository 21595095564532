import {MdCloudDone, VscLoading} from "react-icons/all";
import React from "react";
import {useIsFetching, useQueryClient} from "react-query";
import {makeStyles} from "@material-ui/core/styles";
import {Fade} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(() => ({
    iconSpin: {
        "-webkit-animation": "$iconSpin 2s infinite linear",
        animation: "$iconSpin 2s infinite linear"
    },

    "@-webkit-keyframes iconSpin": {
        "0%": {
            "-webkit-transform": "rotate(0deg)",
            transform: "rotate(0deg)"
        },
        "100%": {
            "-webkit-transform": "rotate(359deg)",
            transform: "rotate(359deg)"
        }
    },
    "@keyframes iconSpin": {
        "0%": {
            "-webkit-transform": "rotate(0deg)",
            transform: "rotate(0deg)"
        },
        "100%": {
            "-webkit-transform": "rotate(359deg)",
            transform: "rotate(359deg)"
        }
    }
}));

const SyncDataIndicator = () => {
    const classes = useStyles();
    const isFetching = useIsFetching();
    const queryClient = useQueryClient();

    return isFetching
        ? <VscLoading size={24} className={classes.iconSpin}/>
        : <Fade in={!isFetching} timeout={150}>
            <IconButton onClick={() => queryClient.invalidateQueries()} size="small">
                <MdCloudDone size={24} color="white"/>
            </IconButton>
        </Fade>;
}

export {SyncDataIndicator}