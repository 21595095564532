import React, {useMemo} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Container from "@material-ui/core/Container";
import {useCompanyContext} from "../context/CompanyContext";
import MainToolbar from "../modules/Admin/components/MainToolbar";
import {Hidden, useTheme} from "@material-ui/core";
import {useBooleanState} from "../utils/hooks";
import BottomNavigation from "../modules/Admin/components/BottomNavigation";
import MenuDrawer from "../modules/Admin/components/Menu/MenuDrawer";
import MainContent from "../modules/Admin/components/MainContent";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarSelectedCompany: {
        backgroundColor: 'green'
    },
    menuButton: {
        marginRight: 36,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    }
}));

export default function Admin(props) {
    const companyContext = useCompanyContext();
    const classes = useStyles();
    const { window } = props
    const [open, handleDrawerOpen, handleDrawerClose] = useBooleanState(false);
    const theme = useTheme();
    const container = window !== undefined ? () => window().document.body : undefined;
    const drawer = useMemo(() => <MenuDrawer onClickItem={handleDrawerClose} />, []);
    const mainContent = useMemo(() => <MainContent/>, []);

    return <div className={classes.root}>
        <CssBaseline/>
        <AppBar position="fixed" className={clsx(classes.appBar, companyContext.selectedCompany && classes.appBarSelectedCompany)}>
            <Toolbar className={classes.toolbar}>
                <Hidden mdUp>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton)}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Hidden>

                <MainToolbar/>
            </Toolbar>
        </AppBar>
        <Hidden smDown>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper),
                }}
                open={open}
            >
                {drawer}
            </Drawer>
        </Hidden>
        <Hidden mdUp>
            <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={open}
                onClose={handleDrawerClose}
                classes={{
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                {drawer}
            </Drawer>
        </Hidden>
        <main className={classes.content}>
            <div className={classes.appBarSpacer}/>
            <Hidden mdUp>
                {mainContent}
            </Hidden>
            <Hidden smDown>
                <Container maxWidth="xl" className={classes.container}>
                    {mainContent}
                </Container>
            </Hidden>
        </main>
        <BottomNavigation/>
    </div>;
}