import React from 'react';
import PhotoForm from "./Service/PhotoForm";
import PicturesForm from "./Service/PicturesForm";
import ObituaryForm from "./Service/ObituaryForm";
import PlateForm from "./Service/PlateForm";
import {FormControl} from "@material-ui/core";

export default function OrderMetadataForm({serviceType, injectErrors, deceasedId}) {
    const getFormForType = serviceType => {
        switch (serviceType) {
            case 'photo':
                return <PhotoForm injectErrors={injectErrors} deceasedId={deceasedId}/>;
            case 'pictures':
                return <PicturesForm injectErrors={injectErrors} deceasedId={deceasedId}/>;
            case 'obituary':
                return <ObituaryForm injectErrors={injectErrors} deceasedId={deceasedId}/>;
            case 'plate':
                return <PlateForm injectErrors={injectErrors} deceasedId={deceasedId}/>;
            default:
                return 'not found';
        }
    }

    return <FormControl fullWidth style={{maxWidth: 400, minWidth: 250}}>
        {getFormForType(serviceType)}
    </FormControl>;
}