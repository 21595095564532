import moment from 'moment-with-locales-es6';

moment.locale('pl');// todo from config?

const dateFormat = 'DD.MM.YYYY';
const dateTimeFormat = 'DD.MM.YYYY, HH:mm';

const handleDate = (date, format) => date ? moment(date).format(format) : null
const fromNow = date => moment(date).fromNow();
const dateToString = (date, format = null) => handleDate(date, format ?? dateFormat);
const dateTimeToString = (dateTime, format = null) => handleDate(dateTime, format ?? dateTimeFormat);

export {dateToString, dateTimeToString, fromNow}