import makeStyles from "@material-ui/core/styles/makeStyles";
import {useConfig} from "../../../config/config";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import React from "react";

const useStyles = makeStyles({
    versions: {
        color: 'rgba(0, 0, 0, 0.30)'
    }
});
export default function Copyright() {
    const classes = useStyles();
    const config = useConfig();

    return <>
        <Typography variant="body1" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="mailto:jakub.sladek@zoho.com">
                Jakub Sładek
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center" className={classes.versions}>
            Client: <b>{config.frontendVersion}</b> Backend: <b>{config.backendVersion}</b>
        </Typography>
    </>;
}