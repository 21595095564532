import {makeStyles} from "@material-ui/core/styles";
import {useUserContext} from "../../../../context/UserContext";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {Avatar} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
    emailBox: {
        fontSize: '10px',
        color: "grey",
    },
}));
export default function UserDetails() {
    const classes = useStyles();
    const {name, email, acronym} = useUserContext();

    return <Box display="flex" justifyContent="center" m={1} p={1} id="userDetailsBox">
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="center" m={1} p={0}>
                    <Avatar>{acronym}</Avatar>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="center" m={0} p={0}>
                    {name}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="center" m={0} p={0} className={classes.emailBox}>
                    {email}
                </Box>
            </Grid>
        </Grid>
    </Box>;
}