import React from "react";
import MassForm from "./MassForm";
import {useUpdateMass} from "../../../Deceased/hooks/deceased";
import {useFetchChurches} from "../../../Deceased/hooks/church";
import WaveSkeleton from "../../../Common/components/WaveSkeleton";

const UpdateMassForm = ({mass, deceasedId, onSuccess}) => {
    const {form: {control, handleSubmit, register, reset}, loading, injectErrors} = useUpdateMass({mass, deceasedId, onSuccess})
    const {churches, isFetching} = useFetchChurches();

    if (isFetching) return <WaveSkeleton/>

    return <MassForm
        control={control}
        loading={loading}
        register={register}
        injectErrors={injectErrors}
        churches={churches}
        handleSubmit={handleSubmit}
        reset={reset}
        mass={mass}
    />;
}

export default UpdateMassForm;