import {Button, FormControl, FormGroup, TextField} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {AiOutlineClose} from "react-icons/all";
import React from "react";
import {useAddAdditionalInformation} from "../hooks/api";

const AdditionalInformationForm = (
    {
        orderId,
        defaultValue,
        onSuccess = () => {},
        exitEditMode = undefined
    }) => {
    const {
        injectErrors,
        form: {handleSubmit, register},
        loading,
    } = useAddAdditionalInformation({
        orderId,
        onSuccess,
        formValues: {
            defaultValues: {
                additionalInformation: defaultValue
            }
        }
    });

    return <form noValidate onSubmit={handleSubmit}>
        <FormControl component="fieldset" fullWidth>
            <FormGroup>
                <TextField
                    autoFocus
                    name="additionalInformation"
                    type="text"
                    variant="outlined"
                    margin="dense"
                    multiline
                    inputRef={register}
                    {...injectErrors('additionalInformation')}
                />
            </FormGroup>
        </FormControl>
        <Button
            size="small"
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
        >
            {loading ? <CircularProgress size={24}/> : 'Zapisz'}
        </Button>
        {exitEditMode ?
            <IconButton onClick={exitEditMode}>
                <AiOutlineClose />
            </IconButton>
            : <></>
        }

    </form>
};

export default AdditionalInformationForm;