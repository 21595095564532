import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToApp from "@material-ui/icons/ExitToApp";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import React from "react";
import {useUserAuth} from "../../../User/hooks/auth";

const UserListItems = () => {
    const {doLogout} = useUserAuth();

    return <ListItem button onClick={doLogout} data-test="logoutButton">
        <ListItemIcon>
            <ExitToApp />
        </ListItemIcon>
        <ListItemText primary="Wyloguj" />
    </ListItem>
}

export default UserListItems;