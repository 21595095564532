import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {Hidden, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import getIcon from "../models/serviceTypeIcon";

const useStyles = makeStyles(theme => ({
    submit: {
        margin: theme.spacing(1)
    }
}));

export default function ServiceType({serviceType}) {
    const classes = useStyles();
    const {t} = useTranslation('order');

    return (
        <Grid container direction="row" alignItems="center">
            <Grid item>
                {getIcon(serviceType)}
            </Grid>
            <Hidden smDown>
                <Grid item>
                    <Typography className={classes.submit}>{t(`serviceTypes.${serviceType}`)}</Typography>
                </Grid>
            </Hidden>
        </Grid>
    );
}