import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import React from "react";
import {FaCross, FaStarOfLife} from "react-icons/all";
import {dateToString} from "../../../utils/time";

export default function BirthAndDeathDate({deceased}) {
    const birthDate = dateToString(deceased.birthDate);
    const deathDate = dateToString(deceased.deathDate)

    return (
        <Grid container direction="column">
            <Grid item>
                <Typography>
                    {birthDate && <><FaStarOfLife/> {birthDate}</>}
                </Typography>
            </Grid>
            <Grid item>
                <Typography>
                    {deathDate && <><FaCross/> {deathDate}</>}
                </Typography>
            </Grid>
        </Grid>
    );
}