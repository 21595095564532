import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {DateWithFromNow} from "../../../Common/components/Listing/DateWithFromNow";
import React from "react";

export default function DeceasedFuneralDetails({deceased}) {
    return <>
        {deceased?.funeral ?
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell><DateWithFromNow date={deceased.funeral?.date}/></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Kościół</TableCell>
                            <TableCell>{deceased.funeral?.church?.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Cmentarz</TableCell>
                            <TableCell>{deceased.funeral?.cemetery?.name}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            : "Brak pogrzebu"
        }
    </>;
}