import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import React from "react";
import {dateToString} from "../../../../utils/time";
import {FaCross, FaStarOfLife} from "react-icons/all";

export default function DeceasedDetails({deceased}) {
    const birthDate = dateToString(deceased.birthDate);
    const deathDate = dateToString(deceased.deathDate)

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Imię i nazwisko</TableCell>
                        <TableCell>
                            {deceased.firstName} {deceased.lastName}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Data urodzenia</TableCell>
                        <TableCell>
                            {birthDate ?
                                <><FaStarOfLife/> {birthDate}</>
                                : "-"
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Data śmierci</TableCell>
                        <TableCell>
                            {deathDate ?
                                <><FaCross/> {deathDate}</>
                                : "-"
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Wiek</TableCell>
                        <TableCell>
                            {deceased.age ?
                                <>{deceased.age.years} lat</>
                                : "-"
                            }
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}