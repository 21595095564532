import Typography from "@material-ui/core/Typography";
import PhotoPreview from "../PhotoPreview";
import React from "react";

const PhotoComponent = ({photoId}) => {
    return photoId && <>
            <Typography variant="overline" color="textSecondary">
                Zdjęcie
            </Typography>
            <PhotoPreview photoId={photoId}/>
        </>;
}

export default PhotoComponent;