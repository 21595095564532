import Admin from "./Admin";
import React from "react";
import {CompanyContextProvider} from "../context/CompanyContext";
import {CompaniesFetcherContextProvider} from "../context/CompaniesFetcherContext";
import {PageContextProvider} from "../context/PageContext";
import {UserContextProvider} from "../context/UserContext";

export default function AdminWrapper() {
    return <UserContextProvider>
        <PageContextProvider>
            <CompaniesFetcherContextProvider>
                <CompanyContextProvider>
                    <Admin/>
                </CompanyContextProvider>
            </CompaniesFetcherContextProvider>
        </PageContextProvider>
    </UserContextProvider>;
}