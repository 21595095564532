import photoApi from "../models/photo";
import {useQuery} from "react-query";

const useFetchPhoto = photoId => {
    const {isLoading, data} = useQuery(
        ['photo', photoId],
        () =>  photoApi.fetch(photoId).then(res => res.data)
    );

    return {photo: data, isFetching: isLoading}
}

export {useFetchPhoto}