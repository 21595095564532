import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {MdEdit} from "react-icons/all";
import MassEditDialog from "./MassEditDialog";
import MassDetails from "./MassDetails";
import React from "react";
import {useTranslation} from "react-i18next";
import {useBooleanState} from "../../../../utils/hooks";
import {useFetchMass} from "../../../Deceased/hooks/deceased";

const MassDetailsWithEdit = ({massId, deceasedId}) => {
    const {t} = useTranslation('order');
    const [open, handleOpen, handleClose] = useBooleanState(false);
    const {mass, isFetching, fetch} = useFetchMass({deceasedId, massId});

    const onSuccessEdit = () => {
        fetch();
        handleClose();
    }

    return <>
        <Typography variant="overline" color="textSecondary">
            {t('massInformation')}
        </Typography>
        <IconButton
            size="small"
            onClick={handleOpen}
        >
            <MdEdit/>
        </IconButton>
        {open &&
        <MassEditDialog
            open={open}
            handleClose={handleClose}
            mass={mass}
            deceasedId={deceasedId}
            isFetching={isFetching}
            onSuccess={onSuccessEdit}
        />}
        <MassDetails
            mass={mass}
            isFetching={isFetching}
        />
    </>;
}

export default MassDetailsWithEdit;