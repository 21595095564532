import React from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import {useTranslation} from "react-i18next";
import {useCompanyContext} from "../../../context/CompanyContext";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import StyledDialog from "../../Common/components/Styled/StyledDialog";
import {useCreateDeceased} from "../hooks/deceased";
import DeceasedDetailsForm from "../components/Form/DeceasedDetailsForm";
import SpinningSubmitButton from "../../Common/components/Form/SpinningSubmitButton";
import {FormControl} from "@material-ui/core";

export default function CreateDeceased(props) {
    const {open, onClose} = props;
    const {t} = useTranslation('deceased');
    const {selectedCompany} = useCompanyContext();

    const {loading, injectErrors, form: {register, handleSubmit}} = useCreateDeceased({onSuccess: onClose});

    return <StyledDialog
        open={open}
        onClose={onClose}
        titleText={t('addDeceased')}
    >
        <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
                {selectedCompany &&
                    <Alert severity="info">
                        <AlertTitle>Uwaga!</AlertTitle>
                        Dodajesz dla innej firmy: <strong>{selectedCompany.name}</strong>
                    </Alert>
                }
                <FormControl fullWidth>
                    <DeceasedDetailsForm register={register} injectErrors={injectErrors}/>
                    <FormControl margin="normal">
                        <SpinningSubmitButton loading={loading}>
                            {t('action.save', {ns: 'common'})}
                        </SpinningSubmitButton>
                    </FormControl>
                </FormControl>
            </DialogContent>
        </form>
    </StyledDialog>;
}