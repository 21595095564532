import React, {useEffect, useState} from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import {useCompanyContext} from "../../../context/CompanyContext";
import {IconButton} from "@material-ui/core";
import {FaUserSecret} from "react-icons/all";
import ListSubheader from "@material-ui/core/ListSubheader";
import StyledDialog from "../../Common/components/Styled/StyledDialog";
import {useBooleanState} from "../../../utils/hooks";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../../context/UserContext";
import AclHidden from "../../ACL/components/AclHidden";

export default function CompanyContextSelect({companies}) {
    const [open, handleOpen, handleClose] = useBooleanState(false);
    const companyContext = useCompanyContext();
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [availableCompanies, setAvailableCompanies] = useState(companies);
    const {t} = useTranslation('admin');
    const {company: userCompany} = useUserContext();

    useEffect(() => {
        companyContext.setSelectedCompany(selectedCompany);
    }, [selectedCompany, companies, companyContext])

    useEffect(() => {
        setAvailableCompanies(companies.filter(company => userCompany.id !== company.id))
    }, [companies, userCompany.id])

    const handleListItemClick = (value) => {
        setSelectedCompany(value);
        handleClose();
    }

    const selectedCompanyId = selectedCompany?.id;

    return <AclHidden permission="ChangeCompanyContext">
        <IconButton onClick={handleOpen}>
            <FaUserSecret color="white"/>
        </IconButton>
        <StyledDialog
            open={open}
            onClose={handleClose}
            titleText={t('showPanelInCompanyContext')}
        >
            <List>
                <ListItem button onClick={() => handleListItemClick(null)} key={0}>
                    <ListItemText
                        primary={userCompany.name}
                        secondary={t('noContext')}
                    />
                </ListItem>
                <ListSubheader>Pozostałe firmy</ListSubheader>
                {availableCompanies.map(company => <ListItem
                        button
                        onClick={() => handleListItemClick(company)}
                        key={company.id}
                    >
                        <ListItemText
                            primary={selectedCompanyId === company.id ? <b>{company.name}</b> : company.name}
                            secondary={selectedCompanyId === company.id && t('currentContext')}
                        />
                    </ListItem>)}
            </List>
        </StyledDialog>
    </AclHidden>;
}