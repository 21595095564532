import React, {createContext, useContext} from "react";
import userDataStorage from "../services/user/UserDataStorage";

const UserContext = createContext(null);

const useUserContext = () => useContext(UserContext);

const UserContextProvider = ({children}) => {
    const user = userDataStorage.getUserDetails();
    user.acronym = user.name.split(' ').map(word => word.charAt(0).toUpperCase());

    return <UserContext.Provider value={user}>
        {children}
    </UserContext.Provider>;
}

export {UserContext, useUserContext, UserContextProvider};