import {useMessages} from "../../../utils/hooks";
import deceasedApi from "../models/deceased";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery, useQueryClient} from "react-query";

const useFetchDeceasedPhotos = deceasedId => {
    const {data, isLoading} = useQuery(
        ['deceasedPhotos', deceasedId],
        () => deceasedApi.fetchAllPhotos({deceasedId}).then(res => res.data)
    )

    return {photos: data, isFetching: isLoading}
}

const useUploadDeceasedPhoto = ({deceasedId}) => {
    const {error: errorFunc} = useMessages();
    const {t} = useTranslation('common');
    const queryClient = useQueryClient();

    const mutation = useMutation(
        files => {
            const data = new FormData();
            data.append('file', files[0])

            return deceasedApi.uploadPhoto(deceasedId, data);
        },
        {
            onSuccess: () => queryClient.invalidateQueries(['deceasedPhotos', deceasedId]),
            onError: () => errorFunc(t('errorOccurred')),
        });


    const upload = files => mutation.mutate(files);

    return {isUploading: mutation.isLoading, upload};
}

const useDeleteDeceasedPhoto = ({deceasedId, photoId}) => {
    const {error: errorFunc} = useMessages();
    const {t} = useTranslation('common');
    const queryClient = useQueryClient();

    const mutation = useMutation(
        ({deceasedId, photoId}) => deceasedApi.deletePhoto(deceasedId, photoId),
        {
            onSuccess: () => queryClient.invalidateQueries(['deceasedPhotos', deceasedId]),
            onError: () => errorFunc(t('errorOccurred')),
        });

    const handleDelete = () => mutation.mutate({deceasedId, photoId});

    return {isDeleting: mutation.isLoading, handleDelete}
}

export {useDeleteDeceasedPhoto, useUploadDeceasedPhoto, useFetchDeceasedPhotos}