import {Typography} from "@material-ui/core";
import {dateTimeToString, fromNow} from "../../../../utils/time";
import React from "react";

export function DateWithFromNow({date, oneLine = false}) {
    if (date) {
        const dateObject = new Date(date);

        return <>
            {
                oneLine ?
                    <>
                        <Typography display="inline">
                            {dateTimeToString(dateObject)}
                        </Typography>
                        <Typography display="inline" color="textSecondary" variant="body2">
                            &nbsp;({fromNow(dateObject)})
                        </Typography>
                    </>
                :
                    <>
                        <Typography>
                            {dateTimeToString(dateObject)}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                            {fromNow(dateObject)}
                        </Typography>
                    </>
            }
        </>;
    }

    return '---'
}