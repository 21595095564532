import {TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React from "react";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core/styles";
import TableFooter from "@material-ui/core/TableFooter";
import Pictures from "./service/Pictures";
import Photos from "./service/Photos";
import Plates from "./service/Plates";
import Obituaries from "./service/Obituaries";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
    },
    paperEmpty: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        display: 'flex',
    }
}));

export default function ReportTable({data}) {
    const classes = useStyles();
    const isEmpty = data.items.length === 0;
    return (
        <>
            {isEmpty && <Paper className={classes.paperEmpty}>Brak danych</Paper>}
            {!isEmpty && <TableContainer component={Paper} className={classes.paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell width="25px">Lp.</TableCell>
                            <TableCell>Zmarły</TableCell>
                            <TableCell>Obrazki</TableCell>
                            <TableCell>Tabliczki</TableCell>
                            <TableCell>Klepsydry</TableCell>
                            <TableCell>Zdjęcie</TableCell>
                            <TableCell>Suma</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.items.map((row, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{row.sequenceNumber}</TableCell>
                                    <TableCell>{row.deceased.fullName}</TableCell>
                                    <TableCell>
                                        <Pictures data={row.pictures}/>
                                    </TableCell>
                                    <TableCell>
                                        <Plates data={row.plates}/>
                                    </TableCell>
                                    <TableCell>
                                        <Obituaries data={row.obituaries}/>
                                    </TableCell>
                                    <TableCell>
                                        <Photos data={row.photos}/>
                                    </TableCell>
                                    <TableCell>{row.totalAmount.formatted}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell align="right" colSpan={6}>Suma:</TableCell>
                            <TableCell>{data.totalAmount.formatted}</TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            }
        </>
    );
}