import React from "react";
import {useFetchChurches} from "../../../Deceased/hooks/church";
import WaveSkeleton from "../../../Common/components/WaveSkeleton";
import {useAddMass} from "../../../Deceased/hooks/deceased";
import MassForm from "./MassForm";

const AddMassForm = ({deceasedId, onSuccess}) => {
    const {injectErrors, loading, form: {handleSubmit, register, control, reset}} = useAddMass({deceasedId, onSuccess});
    const {churches, isFetching} = useFetchChurches();

    if (isFetching) return <WaveSkeleton/>;

    return <MassForm
        control={control}
        loading={loading}
        register={register}
        injectErrors={injectErrors}
        churches={churches}
        handleSubmit={handleSubmit}
        reset={reset}
    />;
}


export default AddMassForm;