import Typography from "@material-ui/core/Typography";
import React from "react";
import {useTranslation} from "react-i18next";
import OrderDetailsGrid from "./Helper/OrderDetailsGrid";
import PhotoComponent from "./Helper/PhotoComponent";
import MassComponent from "./Helper/MassComponent";
import InlineChip from "../../../Common/components/InlineChip";

const PicturesDetails = ({metadata, deceasedId}) => {
    const {t} = useTranslation('order');

    return <OrderDetailsGrid>
        <>
            <Typography variant="overline" color="textSecondary">
                Szczegóły
            </Typography>
            <Typography variant="body2" gutterBottom>
                {metadata.quantity} szt.
                <InlineChip label={t(`pictures.type.${metadata.type}`)}/>
                <br/>
                {metadata.customSaint && `Wybrany święty: ${metadata.customSaint}`}
            </Typography>
        </>
        <PhotoComponent photoId={metadata.photoId} />
        <MassComponent massId={metadata.massId} deceasedId={deceasedId}/>
    </OrderDetailsGrid>
}

export default PicturesDetails
