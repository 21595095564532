import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import getIcon from "../models/serviceTypeIcon";
import OrderMetadataForm from "./OrderMetadataForm";
import Stepper from "@material-ui/core/Stepper";
import React, {useState} from "react";
import OrderApi from "../models/order";
import {FormProvider, useForm} from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import useServiceType from "../hooks/service";
import {useFormHelper, useValidationErrors} from "../../../utils/hooks";
import AfterCreateOrderActions from "./AfterCreateOrderActions";
import SpinningSubmitButton from "../../Common/components/Form/SpinningSubmitButton";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paperWithMargin: {
        marginTop: theme.spacing(3)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export default function CreateOrderForm({deceasedId, history}) {
    const classes = useStyles();
    const formMethods = useForm();
    const {handleSubmit} = formMethods;
    const [serviceType, setServiceType] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [createdOrder, setCreatedOrder] = useState(null);
    const {getServiceLabel, serviceTypes} = useServiceType();
    const chooseServiceType = (serviceType) => {
        setServiceType(serviceType);
        setActiveStep(1);
    }
    const {removeEmptyFields} = useFormHelper();

    const {resetErrors, handleError, injectErrors} = useValidationErrors();

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setServiceType(null);
    };

    const doSubmit = data => {
        data.deceasedId = deceasedId;
        data.serviceType = serviceType;

        setIsLoading(true);
        resetErrors();

        removeEmptyFields(data.serviceMetadata);

        OrderApi.create(data)
            .then(response => {
                const orderData = response.data;
                setCreatedOrder(orderData);
            })
            .catch(error => handleError(error))
            .finally(() => setIsLoading(false));
    }

    return (
        <>
            {createdOrder
                ? <Paper className={classes.paperWithMargin}>
                    <AfterCreateOrderActions
                        message='Co chcesz zrobić dalej?'
                        messageTitle={`Zamówienie ${createdOrder.individualId} zostało utworzone`}
                        orderId={createdOrder.id}
                        onAddNextOrder={() => {
                            setActiveStep(0);
                            setCreatedOrder(null);
                            setServiceType(null);
                        }}
                        onBackToList={() => history.push('/admin/deceased')}
                        onGoToDeceased={() => history.push(`/admin/deceased/${deceasedId}`)}
                    />
                </Paper>
                : <Stepper activeStep={activeStep} orientation="vertical" component={Paper}>
                    <Step key={1}>
                        <StepLabel
                            onClick={() => setActiveStep(0)}
                        >
                            Wybierz typ {null !== serviceType ? ` (wybrano: ${getServiceLabel(serviceType)})`: ''}
                        </StepLabel>
                        <StepContent>
                            <Grid container spacing={3}>
                                {serviceTypes.map((serviceType) => {
                                    return (
                                        <Grid key={serviceType.type} item xs={12} sm={12} md={6} lg={3}>
                                            <Button
                                                data-test-service-type={serviceType.type}
                                                variant="text"
                                                color="primary"
                                                onClick={() => chooseServiceType(serviceType.type)}
                                                className={classes.button}
                                                startIcon={getIcon(serviceType.type)}
                                            >
                                                {serviceType.label}
                                            </Button>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </StepContent>
                    </Step>
                    <Step key={2}>
                        <StepLabel>Wypełnij szczegóły zlecenia</StepLabel>
                        <StepContent>
                            <FormProvider {...formMethods} injectErrors={injectErrors}>
                                <form noValidate onSubmit={handleSubmit(doSubmit)}>
                                    <OrderMetadataForm
                                        serviceType={serviceType}
                                        injectErrors={injectErrors}
                                        deceasedId={deceasedId}
                                    />
                                    <div className={classes.actionsContainer}>
                                        <div>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.button}
                                            >
                                                Zmień typ
                                            </Button>
                                            <SpinningSubmitButton
                                                loading={isLoading}
                                                ButtonProps={{
                                                    className: classes.button
                                                }}
                                            >
                                                Utwórz zlecenie
                                            </SpinningSubmitButton>
                                        </div>
                                    </div>
                                </form>
                            </FormProvider>
                        </StepContent>
                    </Step>
                </Stepper>}

        </>
    )
}