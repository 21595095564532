import {useUserContext} from "../../../context/UserContext";
import {useMemo} from "react";

// todo move these permissions to some config files

const permissions = {
    "ShowSettings": ["ROLE_ADMIN"],
    "ChangeCompanyContext": ["ROLE_ADMIN"],
    "ShowOrders": ["ROLE_WORKER"],
    "SeeFeesWithoutValues": ["ROLE_ADMIN"],
    "SeeReportsForOtherComapanies": ["ROLE_ADMIN"]
};

const rolesHierarchy = {
    "ROLE_ADMIN": ["ROLE_WORKER", "ROLE_ORDERER"],
    "ROLE_WORKER": ["ROLE_ORDERER"],
}

const getSubRoles = role => rolesHierarchy[role] ?? [];

const getAllSubRoles = roles => roles.reduce((previous, role) => [...previous, ...getSubRoles(role), role], []);

const useAcl = (permission) => {
    const {roles} = useUserContext();

    return useMemo(() => {
        const availableRoles = permissions[permission] ?? [];
        const allRoles = getAllSubRoles(roles);

        return availableRoles.filter(role => allRoles.includes(role)).length > 0
    }, [permissions]);
}

export {useAcl}