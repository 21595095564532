import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import SimpleColumnGrid from "../../../Common/components/SimpleColumnGrid";
import {MyKeyboardDateTimePicker} from "../../../Common/components/Pickers/Pickers";
import ChurchSelect from "../../../Deceased/components/Input/ChurchSelect";
import {FormControl, useMediaQuery} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SpinningButtonLabel from "../../../Common/components/SpinningButtonLabel";
import React, {useEffect, useState} from "react";
import {useMassTypes} from "../../../Deceased/hooks/mass";
import {useDateFormat, useSwitchState} from "../../../../utils/hooks";
import {useTranslation} from "react-i18next";

const MassForm = ({register, injectErrors, loading, handleSubmit, churches, control, reset, mass}) => {
    const {t} = useTranslation('order');
    const {types} = useMassTypes();
    const {dateTimeToString} = useDateFormat();
    const [date, handleDateChange] = useState(mass?.date ?? null);
    const [type, handleChangeType] = useSwitchState(mass?.type ?? 'thirty_day');
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const width = mobile ? 125 : 150;

    useEffect(() => {
        if (mass) {
            const massDate = dateTimeToString(mass.date, 'YYYY-MM-DD hh:mm');
            mass.date = massDate;

            reset({
                type: mass.type,
                date: mass.date,
                churchId: mass.church?.id ?? null
            })
            handleDateChange(massDate);
        }
    }, [mass]);


    return <FormControl component="fieldset" fullWidth>
        <input type="hidden" name="type" value={type} ref={register}/>
        <ToggleButtonGroup
            size="large"
            exclusive
            value={type}
            onChange={handleChangeType}
        >
            {types.map(({name, icon}) =>
                <ToggleButton key={name} value={name} style={{width}}>
                    <SimpleColumnGrid>
                        {icon}
                        {t(`massTypes.${name}`, {ns: 'deceased'})}
                    </SimpleColumnGrid>
                </ToggleButton>
            )}
        </ToggleButtonGroup>
        <MyKeyboardDateTimePicker
            fullWidth
            {...injectErrors('date')}
            margin="normal"
            autoOk
            clearable
            label="Data"
            minutesStep={5}
            value={date}
            onChange={handleDateChange}
            inputVariant="outlined"
            name="date"
            inputRef={register}
        />
        <ChurchSelect churches={churches} control={control} defaultChurchId={mass?.church?.id}/>
        <FormControl margin="normal">
            <Button
                color="primary"
                variant="contained"
                disabled={loading}
                onClick={handleSubmit}
            >
                <SpinningButtonLabel
                    loading={loading}
                    label={t('action.save', {ns: 'common'})}
                />
            </Button>
        </FormControl>
    </FormControl>
}

export default MassForm;
