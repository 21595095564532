import Typography from "@material-ui/core/Typography";
import React from "react";
import {usePlateHook} from "../../hooks/plate";
import OrderDetailsGrid from "./Helper/OrderDetailsGrid";
import PhotoComponent from "./Helper/PhotoComponent";
import InlineChip from "../../../Common/components/InlineChip";

const PlateDetails = ({metadata}) => {
    const {getPlacement, getColor, getShape, getSize} = usePlateHook();
    const shape = getShape(metadata.shape);

    return <OrderDetailsGrid>
        <>
            <Typography variant="overline" color="textSecondary">
                Szczegóły
            </Typography>
            <Typography variant="body2" gutterBottom>
                {metadata.custom
                    ?
                    metadata.custom
                    :
                    <>
                        {getColor(metadata.color)?.label} &nbsp;
                        {getSize(metadata.size)?.label}
                        <InlineChip label={getPlacement(metadata.placement)?.label} /> <br/>
                        {shape.label} ({shape.name})
                    </>
                }
            </Typography>
        </>
        <PhotoComponent photoId={metadata.photoId} />
    </OrderDetailsGrid>;
}

export default PlateDetails;
