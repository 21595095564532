import ClientApi from "../../../services/api/client/ClientApi";

class OrderApi {
    fetchAll = ({page, limit}) => ClientApi.get('/admin/order', {
        params: {page, limit},
    });
    create = data => ClientApi.post('/admin/order', data);
    finish = orderId => ClientApi.post(`/admin/order/${orderId}/finish`);
    cancel = orderId => ClientApi.post(`/admin/order/${orderId}/cancel`);
    addAdditionalInformation = (orderId, data) => ClientApi.put(`/admin/order/${orderId}/additionalInformation`, data);
}

const orderApi = new OrderApi();

export default orderApi;