import {Typography} from "@material-ui/core";
import React from "react";
import {FaChurch, GiTombstone} from "react-icons/all";

export default function FuneralDetails({funeral}) {
    const churchName = funeral?.church?.name;
    const cemeteryName = funeral?.cemetery?.name;

    return <>
        {funeral ? <>
            <Typography>
                <FaChurch/> {churchName}
            </Typography>
            <Typography>
                <GiTombstone/> {cemeteryName}
            </Typography>
        </>: '---'}
    </>;
}