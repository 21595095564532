import {Grid, Typography} from "@material-ui/core";
import React from "react";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";

export default function Service({data, printRow}) {
    return (
        <Grid container direction="column">
            {0 === data.length && <Typography>-</Typography>}
            {data.map((row, index) => {
                return (
                    <Grid item key={row.id}>
                        <PopupState variant="popover" popupId={row.id}>
                            {(popupState) => (
                                <>
                                    <div {...bindTrigger(popupState)} style={{cursor: 'pointer'}}>
                                        {printRow(row)}
                                    </div>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <Box p={2}>
                                            <Typography>
                                                {row.order.individualId} ({row.money.formatted})
                                            </Typography>
                                        </Box>
                                    </Popover>
                                </>
                            )}
                        </PopupState>
                    </Grid>
                );
            })}
        </Grid>
    );
}
