import {useEffect, useState} from "react";
import deceasedApi from "../models/deceased";
import {messageService} from "../../../utils/message/MessageService";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {useCompanyContext} from "../../../context/CompanyContext";
import {useBooleanState, useFormHelper, useValidationErrors} from "../../../utils/hooks";
import {useMutation, useQuery, useQueryClient} from "react-query";

const useFetchDeceased = deceasedId => {
    const {data, isLoading, refetch} = useQuery(
        ['deceased', deceasedId],
        () => deceasedApi.fetch(deceasedId).then(res => res.data)
    );

    return {deceased: data, isFetching: isLoading, fetch: refetch}
}

const useFetchMass = ({deceasedId, massId}) => {
    const {deceased, isFetching} = useFetchDeceased(deceasedId);
    const [mass, setMass] = useState(null);

    useEffect(() => {
        if (deceased) {
            const m = deceased.masses.find(deceasedMass => deceasedMass.id === massId);

            setMass(m);
        }
    }, [deceased]);

    return {isFetching, mass};
}

const useFetchDeceasedOrders = deceasedId => {
    const {isLoading, data} = useQuery(
        ['deceasedOrders', deceasedId],
        () => deceasedApi.fetchAllOrders({deceasedId}).then(res => res.data),
    );

    return {orders: data, isFetching: isLoading}
}

const prepareDetailsRequest = data => {
    for (const [key, value] of Object.entries(data)) {
        if (['birthDate', 'deathDate'].includes(key)) {
            if (value === '') {
                data[key] = null;
            }
        }
    }
}

const useCreateDeceased = ({onSuccess = () => {}} = {}) => {
    const {register, handleSubmit, reset} = useForm();
    const queryClient = useQueryClient();
    const {injectErrors, resetErrors, handleError} = useValidationErrors();
    const {t} = useTranslation('deceased');
    const {selectedCompany} = useCompanyContext();

    const mutation = useMutation(data => {
            resetErrors();
            prepareDetailsRequest(data);

            if (selectedCompany) {
                data['companyId'] = selectedCompany.id
            }

            return deceasedApi.create(data);
        },
        {
            onSuccess: () => {
                messageService.success(t('deceasedCreated'))
                queryClient.invalidateQueries('deceaseds');
                reset();
                onSuccess();
            },
            onError: error => handleError(error)
        }
    );

    const doSubmit = data => mutation.mutate(data);

    return {
        injectErrors,
        loading: mutation.isLoading,
        form: {
            register,
            handleSubmit: handleSubmit(doSubmit)
        }
    };
}
const useUpdateDeceasedDetails = ({deceased, onSuccess = () => {}}) => {
    const {register, handleSubmit, reset} = useForm();
    const {injectErrors, resetErrors, handleError} = useValidationErrors();
    const queryClient = useQueryClient();
    const mutation = useMutation(data => {
            resetErrors();
            prepareDetailsRequest(data);
            return deceasedApi.updateDeceased(deceased.id, data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['deceased', deceased.id])
                reset();
                onSuccess();
            },
            onError: error => handleError(error)
        }
    );

    useEffect(() => reset(deceased), [deceased]);

    const doSubmit = data => mutation.mutate(data);

    return {injectErrors, loading: mutation.isLoading, form: {register, handleSubmit: handleSubmit(doSubmit)}};
}

const useUpdateDeceasedFuneral = ({deceased, onSuccess = () => {}}) => {
    const queryClient = useQueryClient();
    const {register, handleSubmit, reset, control} = useForm();
    const {injectErrors, resetErrors, handleError} = useValidationErrors();
    const mutation = useMutation(data => {
        resetErrors();
        prepareDetailsRequest(data);

        return deceasedApi.updateFuneral(deceased.id, data);
    }, {
        onSuccess: () => {
            reset();
            onSuccess();
            queryClient.invalidateQueries(['deceased', deceased.id])
        },
        onError: error => handleError(error)
    });

    useEffect(() => reset(deceased), [deceased]);

    const doSubmit = data => mutation.mutate(data);

    return {injectErrors, loading: mutation.isLoading, form: {register, handleSubmit: handleSubmit(doSubmit), reset, control}};
}

const useAddMass = ({deceasedId, onSuccess = mass => {}} = {}) => {
    const [loading, startLoading, stopLoading] = useBooleanState(false);
    const {register, handleSubmit, reset, control} = useForm();
    const {removeEmptyFields} = useFormHelper();
    const {injectErrors, resetErrors, handleError} = useValidationErrors();

    const doSubmit = data => {
        startLoading();
        resetErrors();
        removeEmptyFields(data);

        deceasedApi.addMass(deceasedId, data)
            .then(({data: mass}) => {
                reset();
                onSuccess(mass);
            })
            .catch(error => handleError(error))
            .finally(stopLoading);
    }

    return {injectErrors, loading, form: {register, reset, handleSubmit: handleSubmit(doSubmit), control}};
}

const useUpdateMass = ({mass, deceasedId, onSuccess = mass => {}} = {}) => {
    const [loading, startLoading, stopLoading] = useBooleanState(false);
    const {register, handleSubmit, reset, control} = useForm();
    const {removeEmptyFields} = useFormHelper();
    const {injectErrors, resetErrors, handleError} = useValidationErrors();

    const doSubmit = data => {
        startLoading();
        resetErrors();
        removeEmptyFields(data);

        deceasedApi.updateMass(deceasedId, mass.id, data)
            .then(({data: mass}) => {
                reset();
                onSuccess(mass);
            })
            .catch(error => handleError(error))
            .finally(stopLoading);
    }

    return {injectErrors, loading, form: {register, reset, handleSubmit: handleSubmit(doSubmit), control}};
}

export {useUpdateMass, useFetchMass, useAddMass, useUpdateDeceasedFuneral, useUpdateDeceasedDetails, useFetchDeceased, useCreateDeceased, useFetchDeceasedOrders };