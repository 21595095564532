import {KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import React from "react";
import {useTranslation} from "react-i18next";

const MyKeyboardDatePicker = props => {
    const {t} = useTranslation('common');

    return <KeyboardDatePicker
        format="DD-MM-yyyy"
        clearLabel={t('action.clear')}
        cancelLabel={t('action.cancel')}
        invalidDateMessage={t('invalidFormat')}
        {...props}
    />
}

const MyKeyboardDateTimePicker = props => {
    const {t} = useTranslation('common');

    return <KeyboardDateTimePicker
        format="DD-MM-yyyy HH:mm"
        ampm={false}
        clearLabel={t('action.clear')}
        cancelLabel={t('action.cancel')}
        invalidDateMessage={t('invalidFormat')}
        {...props}
    />
}

export {MyKeyboardDatePicker, MyKeyboardDateTimePicker}

