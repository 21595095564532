import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import {useTranslation} from "react-i18next";
import {useBooleanState} from "../../../../utils/hooks";
import TariffItemRowEdit from "./TariffItemRowEdit";

const TariffItemRow = ({item, afterUpdate}) => {
    const {t} = useTranslation('accounting');
    const {money, type} = item;
    const [editMode, openEditMode, closeEditMode] = useBooleanState(false);

    const onSave = () => {
        closeEditMode();
        afterUpdate()
    };

    return <TableRow onClick={openEditMode}>
        {editMode && <TariffItemRowEdit item={item} onSave={onSave} onClose={closeEditMode}/> }
        {!editMode && <>
            <TableCell width="80%">
                {t(`feeTypes.${type}`)}
            </TableCell>
            <TableCell width="20%">
                {money ? money.formatted : '---'}
            </TableCell>
        </>}
    </TableRow>
}

export default TariffItemRow;