import {BsPersonFill, IoMdCalendar, SiGooglecalendar} from "react-icons/all";
import React from "react";

const useMassTypes = () => {
    const types = [
        {name: 'thirty_day', icon: <SiGooglecalendar size={36}/>},
        {name: 'anniversary', icon: <IoMdCalendar size={36}/>},
        {name: 'in_intention', icon: <BsPersonFill size={36}/>}
    ];

    return {types};
};

export {useMassTypes};
