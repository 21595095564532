import TextField from "@material-ui/core/TextField";
import ControlledAutocomplete from "../../../Common/components/Input/ControlledAutocomplete";
import React from "react";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useCreateCemetery} from "../../hooks/cemetery";

export default function CemeterySelect({cemeteries, deceased}) {
    const {create, isLoading} = useCreateCemetery();
    const {control} = useFormContext();
    const {t} = useTranslation('deceased');
    const defaultValue = deceased.funeral?.cemetery?.id ?? null;

    return (
        <ControlledAutocomplete
            disabled={isLoading}
            name="cemeteryId"
            options={cemeteries}
            control={control}
            defaultValue={defaultValue}
            onCreate={async value => {
                const response = await create(value);
                return {id: response?.data?.id, name: value}
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t('cemetery')}
                    variant="outlined"
                    margin="normal"
                />
            )}
        />
    )
}