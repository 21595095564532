import React from 'react';
import WaveSkeleton from "../../../Common/components/WaveSkeleton";
import {useFetchDeceasedPhotos} from "../../hooks/deceasedPhotos";
import DeceasedPhoto from "./DeceasedPhoto";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import DeceasedPhotoUploadButton from "../Photo/DeceasedPhotoUploadButton";
import {Typography} from "@material-ui/core";

const DeceasedPhotos = ({deceasedId}) => {
    const {photos, isFetching} = useFetchDeceasedPhotos(deceasedId);
    const {t} = useTranslation('deceased');

    if (isFetching) return <WaveSkeleton/>;

    return (
        <>
            {photos.length ? <Grid container spacing={1}>
                    {photos.map(photo => (
                        <Grid key={photo.id} item xs={6} sm={4} md={4} >
                            <DeceasedPhoto photo={photo} deceasedId={deceasedId} />
                        </Grid>
                    ))}
                </Grid> :
                <><Typography color="textSecondary">
                    {t('noPhotos')}
                </Typography></>
            }
            <DeceasedPhotoUploadButton deceasedId={deceasedId}/>
        </>
    );
}

export default DeceasedPhotos;