import {
    BottomNavigation as MaterialUiBottomNavigation,
    BottomNavigationAction,
    Hidden, Slide, useScrollTrigger
} from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles({
    bottomNavigation: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        backgroundColor: '#fcfcff'
    }
});

const BottomNavigation = () => {
    return null;

    const classes = useStyles();
    const trigger = useScrollTrigger();
    const [value, setValue] = useState(0);
    const history = useHistory();

    const click = (event, newValue) => {
        setValue(newValue);
    };

    return <Hidden mdUp>
        <Slide appear={false} direction="up" in={!trigger}>
            <div className={classes.bottomNavigation}>
                <MaterialUiBottomNavigation
                    value={value}
                    onChange={click}
                >
                    <BottomNavigationAction
                        component={Link}
                        to="/admin/deceased"
                        label="Zmarli"
                        icon={<PeopleIcon />}
                    />
                    <BottomNavigationAction
                        component={Link}
                        to="/admin/order"
                        label="Zlecenia"
                        icon={<ShoppingCartIcon />}
                    />
                </MaterialUiBottomNavigation>
            </div>
        </Slide>
    </Hidden>;
}

export default BottomNavigation;