import {Link} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PeopleIcon from "@material-ui/icons/People";
import ListItemText from "@material-ui/core/ListItemText";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SettingsMenuItem from "./SettingsMenuItem";
import React from "react";
import AclHidden from "../../../ACL/components/AclHidden";

const MainListItems = ({onClickItem}) => {
    return <>
        <ListItem button component={Link} to="/admin/deceased" onClick={onClickItem}>
            <ListItemIcon>
                <PeopleIcon/>
            </ListItemIcon>
            <ListItemText primary="Zmarli"/>
        </ListItem>
        <AclHidden permission="ShowOrders">
            <ListItem button component={Link} to="/admin/order" onClick={onClickItem}>
                <ListItemIcon>
                    <ShoppingCartIcon/>
                </ListItemIcon>
                <ListItemText primary="Zlecenia"/>
            </ListItem>
        </AclHidden>
        <ListItem button component={Link} to="/admin/monthly_report" onClick={onClickItem}>
            <ListItemIcon>
                <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText primary="Raport"/>
        </ListItem>
        <AclHidden permission="ShowSettings">
            <SettingsMenuItem onClickItem={onClickItem}/>
        </AclHidden>
    </>
};

export default MainListItems;