import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Table from "../../Common/components/Listing/Table";
import {DateWithFromNow} from "../../Common/components/Listing/DateWithFromNow";
import DeceasedNameAndCompany from "../../Deceased/components/DeceasedNameAndCompany";
import ServiceType from "../components/ServiceType";
import OrderStatusWithDate from "../components/OrderStatusWithDate";
import {Typography} from "@material-ui/core";
import {ImCancelCircle, IoMdDoneAll} from "react-icons/all";
import {usePage} from "../../../context/PageContext";
import OrderDetailPanel from "../components/OrderDetailPanel";
import {useCancelOrder, useFinishOrder} from "../hooks/orderMutation";
import {useFetchOrders} from "../hooks/orderQuery";

export default function OrderList() {
    const {t} = useTranslation('order');

    const {finish, isLoading: isFinishing} = useFinishOrder();
    const {cancel, isLoading: isCanceling} = useCancelOrder();

    usePage({
        title: 'Zlecenia',
    });

    const columns = [
        {
            title: t('serviceType'),
            render: rowData => <ServiceType serviceType={rowData.service.type}/>,
        },
        {
            title: t('individualId'),
            render: rowData => <Typography>{rowData.individualId}</Typography>,
        },
        {
            title: t('firstNameAndSurname', {ns: 'deceased'}),
            render: rowData => <DeceasedNameAndCompany deceased={rowData.deceased} />,
        },
        {
            title: t('funeralDate', {ns: 'deceased'}),
            render: rowData => <DateWithFromNow date={rowData.deceased.funeral?.date}/>,
        },
        {
            title: t('createdAt'),
            render: rowData => <OrderStatusWithDate order={rowData}/>,
        }
    ];

    const actions = [
        rowData => ({
            icon: () => <IoMdDoneAll size={26}/>,
            tooltip: t('action.finish'),
            onClick: (event, rowData) => finish(rowData.id),
            disabled: ['finished'].includes(rowData.status)
        }),
        rowData => ({
            icon: () => <ImCancelCircle size={26}/>,
            tooltip: t('action.cancel'),
            onClick: (event, rowData) => cancel(rowData.id),
            disabled: ['finished', 'canceled'].includes(rowData.status)
        }),
    ];


    const [fetcherQuery, setQuery] = useState({page: 0, pageSize: 25});
    const {data, isLoading: fetchingOrders, prefetchSidePages} = useFetchOrders(fetcherQuery);

    useEffect(prefetchSidePages, [fetcherQuery]);

    return <Table
        onChangeQueryParams={(queryParams => setQuery(queryParams))}
        isLoading={isFinishing || isCanceling || fetchingOrders}
        columns={columns}
        data={data?.items ?? undefined}
        page={data?.page ?? 0}
        totalCount={data?.totalItems ?? 0}
        isRemoteSearch={true}
        actions={actions}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        detailPanel={rowData => <OrderDetailPanel order={rowData}/>}
    />;
}