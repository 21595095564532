import cemeteryApi from "../models/cemetery";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useMessages} from "../../../utils/hooks";
import {useTranslation} from "react-i18next";

const useFetchCemeteries = () => {
    const {data, isLoading} = useQuery(
        'cemeteries',
        () => cemeteryApi.fetchAll().then(res => res.data)
    );

    return {cemeteries: data, isFetching: isLoading}
}

const useCreateCemetery = () => {
    const {error: errorFunc} = useMessages();
    const {t} = useTranslation('common');
    const queryClient = useQueryClient();

    const mutation = useMutation(
        name => cemeteryApi.create({name}),
        {
            onSuccess: () => queryClient.invalidateQueries('cemeteries'),
            onError: () => errorFunc(t('errorOccurred'))
        }
    );

    return {
        create: mutation.mutate,
        isLoading: mutation.isLoading
    };
}

export { useFetchCemeteries, useCreateCemetery };
