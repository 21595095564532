import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {ExpandLess, ExpandMore, Settings} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import {Collapse} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";

const SettingsMenuItem = ({onClickItem}) => {
    const [open, setOpen] = useState();
    const history = useHistory();
    const handleOnClick = url => {
        onClickItem();
        history.push(url);
    };

    return <>
        <ListItem button onClick={() => setOpen(!open)}>
            <ListItemIcon>
                <Settings/>
            </ListItemIcon>
            <ListItemText primary="Ustawienia"/>
            {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <List component="div" disablePadding>
                <ListItem button>
                    <ListItemText inset primary="Rozliczenia" onClick={() => handleOnClick('/admin/settings/accounting')}/>
                </ListItem>
            </List>
        </Collapse>
    </>;
}

export default SettingsMenuItem;