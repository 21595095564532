import React from "react";
import EditFuneralDetails from "./EditFuneralDetails";
import DeceasedEditDialog from "./DeceasedEditDialog";

export default function EditFuneralDialog({open, deceased, onClose = () => {}, onSave = () => {}}) {
    return <DeceasedEditDialog
        deceased={deceased}
        onClose={onClose}
        onSave={onSave}
        open={open}
        EditComponent={EditFuneralDetails}
    />;
}