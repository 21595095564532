import Grid from "@material-ui/core/Grid";
import AppBreadcrumbs from "./AppBreadcrumbs";
import SelectedCompany from "./SelectedCompany";
import CompanyContextSelect from "./CompanyContextSelect";
import React from "react";
import {useCompaniesFetcherContext} from "../../../context/CompaniesFetcherContext";
import {Hidden} from "@material-ui/core";
import {SyncDataIndicator} from "./AppBar/SyncDataIndicator";

export default function MainToolbar() {
    const companies = useCompaniesFetcherContext();

    return <Grid container alignItems="center">
        <Grid container item xs={8} sm={8} md={4} jutify="flex-start">
            <AppBreadcrumbs/>
        </Grid>
        <Hidden smDown>
            <Grid container item md={4} justify="center">
                <SelectedCompany/>
            </Grid>
        </Hidden>
        <Grid container item xs={4} sm={4} md={4} justify="flex-end" alignItems="center" alignContent="center">
            <SyncDataIndicator />
            <CompanyContextSelect companies={companies}/>
        </Grid>
    </Grid>;
}