import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import './utils/i18n';
import 'fontsource-roboto';

const theme = createMuiTheme({
    typography: {
        body1: {
            fontSize: 14
        },
        body2: {
            fontSize: 12
        },
        fontSize: 14
    }
    // palette: {
    //     type: "dark",
    //     primary: {
    //         light: "#535353",
    //         main: "#767676",
    //         dark: "#424242",
    //         contrastText: "#d9d9d9"
    //     }
    // }
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
    </ThemeProvider>,
    document.getElementById('root')
);