import {useMessages} from "../../../utils/hooks";
import {useTranslation} from "react-i18next";
import {useMutation, useQueryClient} from "react-query";
import orderApi from "../models/order";

const useFinishOrder = () => {
    const {error: errorFunc} = useMessages();
    const {t} = useTranslation('common');
    const queryClient = useQueryClient();

    const mutation = useMutation(
        orderId => orderApi.finish(orderId),
        {
            onMutate: variables => variables,
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries('orders')
                queryClient.invalidateQueries(['orders', context.orderId])
            },
            onError: () => errorFunc(t('errorOccurred'))
        }
    );

    return {
        finish: mutation.mutate,
        isLoading: mutation.isLoading
    };
};

const useCancelOrder = () => {
    const {error: errorFunc} = useMessages();
    const {t} = useTranslation('common');
    const queryClient = useQueryClient();

    const mutation = useMutation(
        orderId => orderApi.cancel(orderId),
        {
            onMutate: variables => variables,
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries('orders')
                queryClient.invalidateQueries(['orders', context.orderId])
            },
            onError: () => errorFunc(t('errorOccurred'))
        }
    );

    return {
        cancel: mutation.mutate,
        isLoading: mutation.isLoading
    };
};

export {useFinishOrder, useCancelOrder};