import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Button from "@material-ui/core/Button";
import {FaUser, MdNoteAdd, MdUndo} from "react-icons/all";
import React from "react";
import EditableAdditionalInformation from "./EditableAdditionalInformation";
import {Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    littlePadding: {
        padding: 10
    }
})

export default function AfterCreateOrderActions({messageTitle, message, onAddNextOrder, onBackToList, onGoToDeceased, orderId})
{
    const classes = useStyles();
    const {t} = useTranslation('order');

    return <>
        <Grid container spacing={2}>
            {message && messageTitle && <Grid item xs={12}>
                <Alert data-test="createOrderAlert">
                    <AlertTitle>{messageTitle}</AlertTitle>
                    {message}
                </Alert>
            </Grid>}
        </Grid>
        <Grid container>
                <Grid item xs={4} className={classes.littlePadding}>
                    <Typography variant="overline" color="textSecondary">
                        {t('addAdditionalInformation')}
                    </Typography>
                    <EditableAdditionalInformation
                        orderId={orderId}
                        defaultEditMode={true}
                        closable={false}
                    />
                </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12} className={classes.littlePadding}>
                <Typography variant="overline" color="textSecondary">
                    {t('otherActions')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
                <Button
                    variant="text"
                    color="primary"
                    onClick={onAddNextOrder}
                    startIcon={<MdNoteAdd size={36}/>}
                >
                    Utwórz kolejne
                </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
                <Button
                    variant="text"
                    color="primary"
                    onClick={onGoToDeceased}
                    startIcon={<FaUser size={36}/>}
                >
                    Przejdź do zmarłego
                </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
                <Button
                    variant="text"
                    color="primary"
                    onClick={onBackToList}
                    startIcon={<MdUndo size={36}/>}
                >
                    Wróć do listy
                </Button>
            </Grid>
        </Grid>
    </>;
}