import React, {useEffect} from 'react';
import {useFormContext} from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import InputAdornment from "@material-ui/core/InputAdornment";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import TwoRowColumn from "../../../Common/components/TwoRowColumn";
import {useSwitchState, useToggleState} from "../../../../utils/hooks";
import {useTranslation} from "react-i18next";
import {usePicturesHook} from "../../hooks/pictures";
import DeceasedPhotoSelect from "./Input/DeceasedPhotoSelect";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MassFormSelect from "./Input/MassFormSelect";

export default function PicturesForm({injectErrors, deceasedId}) {
    const {register, reset} = useFormContext();
    const [type, handleChangeType] = useSwitchState('saint');
    const {t} = useTranslation('order');
    const {types} = usePicturesHook();
    const [withMass, toggleWithMass] = useToggleState(false);

    useEffect(() => {
        reset({
            serviceMetadata: {
                quantity: 10
            }
        })
    }, [])

    return <>
        <FormGroup style={{marginBottom: 10}}>
            <input type="hidden" name="serviceMetadata[picturesType]" value={type} ref={register}/>
            <ToggleButtonGroup size="large" exclusive value={type} onChange={handleChangeType}>
                {types.map(({name, icon, label}) =>
                    <ToggleButton key={name} value={name} style={{width: '150px'}}>
                        <TwoRowColumn
                            up={icon}
                            bottom={label}
                        />
                    </ToggleButton>
                )}
            </ToggleButtonGroup>
        </FormGroup>
        <FormGroup>
            <TextField
                autoFocus
                {...injectErrors('serviceMetadata.quantity')}
                type="number"
                variant="outlined"
                margin="dense"
                id="quantity"
                name="serviceMetadata[quantity]"
                label={t('howManyPcs')}
                InputProps={{
                    endAdornment: <InputAdornment position="start">szt.</InputAdornment>,
                    inputProps: {
                        min: 10,
                        step: 10
                    }
                }}
                inputRef={register}
            />
        </FormGroup>
        {type === 'saint' && <FormGroup>
            <TextField
                {...injectErrors('serviceMetadata.customSaint')}
                variant="outlined"
                margin="dense"
                id="customSaint"
                name="serviceMetadata[customSaint]"
                label={t('whichSaint')}
                placeholder={t('fillOnlyIfSpecificSaint')}
                inputRef={register}
            />
        </FormGroup>}
        {type === 'photo' && <DeceasedPhotoSelect deceasedId={deceasedId}/>}

        <FormGroup>
            <FormControlLabel
                control={<Checkbox
                    checked={withMass}
                    onChange={toggleWithMass}
                />}
                label={t('massInformation')}
            />
        </FormGroup>
        {withMass &&
        <FormGroup style={{marginBottom: 10}}>
            <MassFormSelect deceasedId={deceasedId} autoOpen/>
        </FormGroup>
        }
    </>;
}