import React from "react";
import {Controller} from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {createFilterOptions} from "@material-ui/lab";

const filter = createFilterOptions();

// https://stackoverflow.com/questions/61655199/proper-way-to-use-react-hook-form-controller-with-material-ui-autocomplete
// https://material-ui.com/components/autocomplete/#creatable

/**
 * options should is object {id, name}
 */
export default function ControlledAutocomplete({options, control, defaultValue, renderInput, name, disabled, onCreate = null}) {
    const defaultValueObject = options.find(option => option.id === defaultValue);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ onChange, ...props }) => (
                <Autocomplete
                    // value={value}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            onChange(newValue.id);
                        } else if (newValue && newValue.inputValue) {
                            if (onCreate) {
                                onCreate(newValue.inputValue)
                                    .then((value) => {
                                        options.push(value);
                                        onChange(value.id)
                                    })
                            }
                        } else if (null === newValue) {
                            onChange(null);
                        } else {
                            onChange(newValue.id);
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (onCreate && params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                name: `Utwórz "${params.inputValue}"`,
                            });
                        }

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={options}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                    }}
                    renderOption={(option) => option.name}
                    freeSolo
                    renderInput={renderInput}
                    defaultValue={defaultValueObject}
                    disabled={disabled}
                />
            )}


        />
    )
}